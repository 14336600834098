import { onCallConstants } from "../constants";
import { onCallServices } from "../services";

export const onCallActions = {
    getSiteUsersStatus,
    getSiteUsersPosition,
    getSiteUsersAcademicYears,
    getSiteUsers,
    
    getSearchAll,
    
    
};

function getSiteUsersStatus(data) {
    return dispatch => {
        dispatch(request());
        onCallServices.getSiteUsersStatus(data)
            .then(
                records => dispatch(success(records)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: onCallConstants.ONCALL_REQUEST }; }
    function success(records) { return { type: onCallConstants.ONCALL_SUCCESS, records }; }
    function failure(error) { return { type: onCallConstants.ONCALL_FAILURE, error }; }
}

function getSiteUsersPosition() {
    return dispatch => {
        dispatch(request());
        onCallServices.getSiteUsersPosition()
            .then(
                records => dispatch(success(records)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: onCallConstants.ONCALL_POSITION_REQUEST }; }
    function success(records) { return { type: onCallConstants.ONCALL_POSITION_SUCCESS, records }; }
    function failure(error) { return { type: onCallConstants.ONCALL_POSITION_FAILURE, error }; }
}

function getSiteUsersAcademicYears() {
    return dispatch => {
        dispatch(request());
        onCallServices.getSiteUsersAcademicYears()
            .then(
                records => dispatch(success(records)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: onCallConstants.ONCALL_ACADEMIC_YEARS_REQUEST }; }
    function success(records) { return { type: onCallConstants.ONCALL_ACADEMIC_YEARS_SUCCESS, records }; }
    function failure(error) { return { type: onCallConstants.ONCALL_ACADEMIC_YEARS_FAILURE, error }; }
}

function getSiteUsers(data) {
    return dispatch => {
        dispatch(request());
        onCallServices.getSiteUsers(data)
            .then(
                records => dispatch(success(records)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: onCallConstants.ONCALL_SITEUSERS_REQUEST }; }
    function success(records) { return { type: onCallConstants.ONCALL_SITEUSERS_SUCCESS, records }; }
    function failure(error) { return { type: onCallConstants.ONCALL_SITEUSERS_FAILURE, error }; }
}


function getSearchAll(search) {
    return dispatch => {
        dispatch(request());
        onCallServices.getSearchAll(search)
            .then(
                records => dispatch(success(records)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: onCallConstants.ONCALL_LSDI_SEARCH_REQUEST }; }
    function success(records) { return { type: onCallConstants.ONCALL_LSDI_SEARCH_SUCCESS, records }; }
    function failure(error) { return { type: onCallConstants.ONCALL_LSDI_SEARCH_FAILURE, error }; }
}