export const onCallConstants = {
    ONCALL_REQUEST: "ONCALL_GETALL_REQUEST",
    ONCALL_SUCCESS: "ONCALL_GETALL_SUCCESS",
    ONCALL_FAILURE: "ONCALL_GETALL_FAILURE",

    ONCALL_POSITION_REQUEST: "ONCALL_POSITION_GETALL_REQUEST",
    ONCALL_POSITION_SUCCESS: "ONCALL_POSITION_GETALL_SUCCESS",
    ONCALL_POSITION_FAILURE: "ONCALL_POSITION_GETALL_FAILURE",

    ONCALL_ACADEMIC_YEARS_REQUEST: "ONCALL_ACADEMIC_YEARS_REQUEST",
    ONCALL_ACADEMIC_YEARS_SUCCESS: "ONCALL_ACADEMIC_YEARS_SUCCESS",
    ONCALL_ACADEMIC_YEARS_FAILURE: "ONCALL_ACADEMIC_YEARS_FAILURE",

    ONCALL_SITEUSERS_REQUEST: "ONCALL_SITEUSERS_GETALL_REQUEST",
    ONCALL_SITEUSERS_SUCCESS: "ONCALL_SITEUSERS_GETALL_SUCCESS",
    ONCALL_SITEUSERS_FAILURE: "ONCALL_SITEUSERS_GETALL_FAILURE",

    ONCALL_ALL_SEARCH_REQUEST: "ONCALL_ALL_SEARCH_GETALL_REQUEST",
    ONCALL_ALL_SEARCH_SUCCESS: "ONCALL_ALL_SEARCH_GETALL_SUCCESS",
    ONCALL_ALL_SEARCH_FAILURE: "ONCALL_ALL_SEARCH_GETALL_FAILURE",


    ONCALL_LSDI_SEARCH_REQUEST: "ONCALL_LSDI_SEARCH_GETALL_REQUEST",
    ONCALL_LSDI_SEARCH_SUCCESS: "ONCALL_LSDI_SEARCH_GETALL_SUCCESS",
    ONCALL_LSDI_SEARCH_FAILURE: "ONCALL_LSDI_SEARCH_GETALL_FAILURE",

    
};
