import React, { Component } from "react";
import { connect } from "react-redux";
import ErrorWarning from "../../assets/error-warning-fill.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { onCallActions } from "../../actions";
import PlacesAutocomplete, { geocodeByAddress, getLatLng, } from "react-places-autocomplete";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Spinner from 'react-bootstrap/Spinner';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {
  Slider,
  SliderInput,
  SliderTrack,
  SliderRange,
  SliderHandle,
  SliderMarker
} from "@reach/slider";
import "@reach/slider/styles.css";
import swal from 'sweetalert';

class Index extends Component {
    placesRef = null;
    constructor(props) {
        super(props);
        this.state = {
            siteUserStatus: [],
            siteUserPosition: [],
            siteUserAcademicYears: [],
            firstCallStatus: (JSON.parse(localStorage.getItem("firstCallStatus")) ? JSON.parse(localStorage.getItem("firstCallStatus")) : 1),
            firstCallStudent: (JSON.parse(localStorage.getItem("firstCallStudent")) ? JSON.parse(localStorage.getItem("firstCallStudent")) : 1),
            firstCallPosition: (JSON.parse(localStorage.getItem("firstCallPosition")) ? JSON.parse(localStorage.getItem("firstCallPosition")) : 1),
            
            half_checklist_dcotor: (JSON.parse(localStorage.getItem("half_checklist_dcotor")) ? JSON.parse(localStorage.getItem("half_checklist_dcotor")) : 0),
            half_checklist_student: (JSON.parse(localStorage.getItem("half_checklist_student")) ? JSON.parse(localStorage.getItem("half_checklist_student")) : 0),
            
            docChecked: (JSON.parse(localStorage.getItem("docChecked")) ? JSON.parse(localStorage.getItem("docChecked")) : true),
            stuChecked: (JSON.parse(localStorage.getItem("stuChecked")) ? JSON.parse(localStorage.getItem("stuChecked")) : false),
            doctorStatusId: (JSON.parse(localStorage.getItem("doctorStatusId")) ? JSON.parse(localStorage.getItem("doctorStatusId")) : ""),
            studentStatusId: (JSON.parse(localStorage.getItem("studentStatusId")) ? JSON.parse(localStorage.getItem("studentStatusId")) : ""),
            selecteDocPosition: (JSON.parse(localStorage.getItem("selecteDocPosition")) ? JSON.parse(localStorage.getItem("selecteDocPosition")) : []),
            selecteStuPosition: (JSON.parse(localStorage.getItem("selecteStuPosition")) ? JSON.parse(localStorage.getItem("selecteStuPosition")) : []),
            doctorPosition: (JSON.parse(localStorage.getItem("doctorPosition")) ? JSON.parse(localStorage.getItem("doctorPosition")) : []),
            studentPosition: (JSON.parse(localStorage.getItem("studentPosition")) ? JSON.parse(localStorage.getItem("studentPosition")) : []),
            
            activeGoogleSearch: JSON.parse(localStorage.getItem("activeGoogleSearch")) == false ? JSON.parse(localStorage.getItem("activeGoogleSearch")) : true,
            searchType: (JSON.parse(localStorage.getItem("searchType")) ? JSON.parse(localStorage.getItem("searchType")) : "ALL"),

            location: (JSON.parse(localStorage.getItem("location")) ? JSON.parse(localStorage.getItem("location")) : []),
            
            location_filter_order: (JSON.parse(localStorage.getItem("location_filter_order")) ? JSON.parse(localStorage.getItem("location_filter_order")) : 0),
            locations_method: (JSON.parse(localStorage.getItem("locations_method")) ? JSON.parse(localStorage.getItem("locations_method")) : "OR"),
            locations_method_status: (JSON.parse(localStorage.getItem("locations_method_status")) ? JSON.parse(localStorage.getItem("locations_method_status")) : 0),
            location_redius: (JSON.parse(localStorage.getItem("location_redius")) ? JSON.parse(localStorage.getItem("location_redius")) : -1),
            location_redius_type: (JSON.parse(localStorage.getItem("location_redius_type")) ? JSON.parse(localStorage.getItem("location_redius_type")) : "MI"),
            locationLatLng: (JSON.parse(localStorage.getItem("locationLatLng")) ? JSON.parse(localStorage.getItem("locationLatLng")) : []),
            locationChecked: (JSON.parse(localStorage.getItem("locationChecked")) ? JSON.parse(localStorage.getItem("locationChecked")) : false),

            address: "",
            lat: "",
            long: "",
            
            specialitChecked: (JSON.parse(localStorage.getItem("specialitChecked")) ? JSON.parse(localStorage.getItem("specialitChecked")) : false),
            specialities: (JSON.parse(localStorage.getItem("specialities")) ? JSON.parse(localStorage.getItem("specialities")) : []),
            speciality_filter_order: (JSON.parse(localStorage.getItem("speciality_filter_order")) ? JSON.parse(localStorage.getItem("speciality_filter_order")) : 0),
            specialities_method: (JSON.parse(localStorage.getItem("specialities_method")) ? JSON.parse(localStorage.getItem("specialities_method")) : "OR"),
            specialities_method_status: (JSON.parse(localStorage.getItem("specialities_method_status")) ? JSON.parse(localStorage.getItem("specialities_method_status")) : 0),
            specialitiesIds: (JSON.parse(localStorage.getItem("specialitiesIds")) ? JSON.parse(localStorage.getItem("specialitiesIds")) : []),
            
            interventionChecked: (JSON.parse(localStorage.getItem("interventionChecked")) ? JSON.parse(localStorage.getItem("interventionChecked")) : false),
            intervention: (JSON.parse(localStorage.getItem("intervention")) ? JSON.parse(localStorage.getItem("intervention")) : []),
            intervention_filter_order: (JSON.parse(localStorage.getItem("intervention_filter_order")) ? JSON.parse(localStorage.getItem("intervention_filter_order")) : 0),
            interventions_method: (JSON.parse(localStorage.getItem("interventions_method")) ? JSON.parse(localStorage.getItem("interventions_method")) : "OR"),
            interventions_method_status: (JSON.parse(localStorage.getItem("interventions_method_status")) ? JSON.parse(localStorage.getItem("interventions_method_status")) : 0),
            interventionIds: (JSON.parse(localStorage.getItem("interventionIds")) ? JSON.parse(localStorage.getItem("interventionIds")) : []),
            
            diseaseChecked: (JSON.parse(localStorage.getItem("diseaseChecked")) ? JSON.parse(localStorage.getItem("diseaseChecked")) : false),
            diseases: (JSON.parse(localStorage.getItem("diseases")) ? JSON.parse(localStorage.getItem("diseases")) : []),
            disease_filter_order: (JSON.parse(localStorage.getItem("disease_filter_order")) ? JSON.parse(localStorage.getItem("disease_filter_order")) : 0),
            diseases_method: (JSON.parse(localStorage.getItem("diseases_method")) ? JSON.parse(localStorage.getItem("diseases_method")) : "OR"),
            diseases_method_status: (JSON.parse(localStorage.getItem("diseases_method_status")) ? JSON.parse(localStorage.getItem("diseases_method_status")) : 0),
            diseasesIds: (JSON.parse(localStorage.getItem("diseasesIds")) ? JSON.parse(localStorage.getItem("diseasesIds")) : []),
            
            advanceFilter: (JSON.parse(localStorage.getItem("advanceFilter")) ? JSON.parse(localStorage.getItem("advanceFilter")) : false),
            
            activeMsgArea: false,
            searchResults: [],
            searchResultsAll: [],
            searchString: "",
            
            count: 0,
            activeSection:false,
            bookmarkType: "",
            member_count: 0,
            member_count_final: 0,
            msg_price: 0,
            total_siteusers: 0,
            i_value: "",
            default_redius: -1,
            default_redius_type: "MI",
            active_location: [],
            //show_redius_input: false,

            address_components: [],
            isLoading: true,
            isLoadingCount: true,
            isLoadingOrder: false,

            isClearSuggestion: true,

            searchTypeL: JSON.parse(localStorage.getItem("searchTypeL")) == false ? JSON.parse(localStorage.getItem("searchTypeL")) : true,
            searchTypeS: JSON.parse(localStorage.getItem("searchTypeS")) == false ? JSON.parse(localStorage.getItem("searchTypeS")) : true,
            searchTypeD: JSON.parse(localStorage.getItem("searchTypeD")) == false ? JSON.parse(localStorage.getItem("searchTypeD")) : true,
            searchTypeI: JSON.parse(localStorage.getItem("searchTypeI")) == false ? JSON.parse(localStorage.getItem("searchTypeI")) : true,

            inputMarkers:[0],
            
            
            andOrFilter1: (JSON.parse(localStorage.getItem("andOrFilter1")) ? JSON.parse(localStorage.getItem("andOrFilter1")) : "AND"),
            andOrFilter1_status: JSON.parse(localStorage.getItem("andOrFilter1_status")) == false ? JSON.parse(localStorage.getItem("andOrFilter1_status")) : true,
            andOrFilter2: (JSON.parse(localStorage.getItem("andOrFilter2")) ? JSON.parse(localStorage.getItem("andOrFilter2")) : "AND"),
            andOrFilter2_status: JSON.parse(localStorage.getItem("andOrFilter2_status")) == false ? JSON.parse(localStorage.getItem("andOrFilter2_status")) : true,
            andOrFilter3: (JSON.parse(localStorage.getItem("andOrFilter3")) ? JSON.parse(localStorage.getItem("andOrFilter3")) : "AND"),
            andOrFilter3_status: JSON.parse(localStorage.getItem("andOrFilter3_status")) == false ? JSON.parse(localStorage.getItem("andOrFilter3_status")) : true,

            showWithOrder:JSON.parse(localStorage.getItem("showWithOrder")) ? JSON.parse(localStorage.getItem("showWithOrder")) : {},
            showWithOrderObj:JSON.parse(localStorage.getItem("showWithOrderObj")) ? JSON.parse(localStorage.getItem("showWithOrderObj")) : [],
            
            free_msg_per: 0,
            total_active_filters:JSON.parse(localStorage.getItem("total_active_filters")) ? JSON.parse(localStorage.getItem("total_active_filters")) : 0,
            orderNumberHtml:[],
            
            temp_var_for_andor1:JSON.parse(localStorage.getItem("temp_var_for_andor1")) ? JSON.parse(localStorage.getItem("temp_var_for_andor1")) : 0,
            temp_var_for_andor2:JSON.parse(localStorage.getItem("temp_var_for_andor2")) ? JSON.parse(localStorage.getItem("temp_var_for_andor2")) : 0,
            temp_var_for_andor3:JSON.parse(localStorage.getItem("temp_var_for_andor3")) ? JSON.parse(localStorage.getItem("temp_var_for_andor3")) : 0,

            temp_var_for_link1:JSON.parse(localStorage.getItem("temp_var_for_link1")) ? JSON.parse(localStorage.getItem("temp_var_for_link1")) : 0,
            temp_var_for_link2:JSON.parse(localStorage.getItem("temp_var_for_link2")) ? JSON.parse(localStorage.getItem("temp_var_for_link2")) : 0,
            temp_var_for_link3:JSON.parse(localStorage.getItem("temp_var_for_link3")) ? JSON.parse(localStorage.getItem("temp_var_for_link3")) : 0,

            show_link1:JSON.parse(localStorage.getItem("show_link1")) ? JSON.parse(localStorage.getItem("show_link1")) : 0,
            show_link2:JSON.parse(localStorage.getItem("show_link2")) ? JSON.parse(localStorage.getItem("show_link2")) : 0,
            show_link3:JSON.parse(localStorage.getItem("show_link3")) ? JSON.parse(localStorage.getItem("show_link3")) : 0,

            show_l_result: false,
            show_s_result: false,
            show_d_result: false,
            show_i_result: false,

            l_old_value: JSON.parse(localStorage.getItem("l_old_value")) ? JSON.parse(localStorage.getItem("l_old_value")) : 0,
            s_old_value: JSON.parse(localStorage.getItem("s_old_value")) ? JSON.parse(localStorage.getItem("s_old_value")) : 0,
            d_old_value : JSON.parse(localStorage.getItem("d_old_value")) ? JSON.parse(localStorage.getItem("d_old_value")) : 0,
            i_old_value: JSON.parse(localStorage.getItem("i_old_value")) ? JSON.parse(localStorage.getItem("i_old_value")) : 0,

        };
        
        this.handleOnSelecteSearch = this.handleOnSelecteSearch.bind(this);
        this.handleOnSelecteAll = this.handleOnSelecteAll.bind(this);
        this.setTotal_active_filters = this.setTotal_active_filters.bind(this);
        this.fillOrderNumberHtml = this.fillOrderNumberHtml.bind(this);
        this.setLinkOption = this.setLinkOption.bind(this);
        this.removeLinking = this.removeLinking.bind(this);
    }

    removeLinking(remove_for){
        let location_filter_order = this.state.location_filter_order;
        let speciality_filter_order = this.state.speciality_filter_order;
        let disease_filter_order = this.state.disease_filter_order;
        let intervention_filter_order = this.state.intervention_filter_order;

        let temp_var = 0;
        
        const showWithOrderOld = this.state.showWithOrder;
        
        let l_old_value = this.state.l_old_value;
        let s_old_value = this.state.s_old_value;
        let d_old_value = this.state.d_old_value;
        let i_old_value = this.state.i_old_value;

        let set_for = [];
        let set_value = [];
        let counter = 0;

        if(remove_for === 1){
            if(this.state.temp_var_for_link1 == 1){
                set_for = [];
                set_value = [];
                counter = 0;
                if(this.state.showWithOrderObj[0] == "L"){ if(l_old_value != 0){ location_filter_order = l_old_value; set_for[counter] = "L"; set_value[counter] = l_old_value; counter++; } }
                if(this.state.showWithOrderObj[0] == "S"){ if(s_old_value != 0){ speciality_filter_order = s_old_value; set_for[counter] = "S"; set_value[counter] = s_old_value; counter++; } }
                if(this.state.showWithOrderObj[0] == "D"){ if(d_old_value != 0){ disease_filter_order = d_old_value; set_for[counter] = "D"; set_value[counter] = d_old_value; counter++; } }
                if(this.state.showWithOrderObj[0] == "I"){ if(i_old_value != 0){ intervention_filter_order = i_old_value; set_for[counter] = "I"; set_value[counter] = i_old_value; counter++; } }
                
                if(this.state.showWithOrderObj[1] == "L"){ if(l_old_value != 0){ location_filter_order = l_old_value; set_for[counter] = "L"; set_value[counter] = l_old_value; counter++; } }
                if(this.state.showWithOrderObj[1] == "S"){ if(s_old_value != 0){ speciality_filter_order = s_old_value; set_for[counter] = "S"; set_value[counter] = s_old_value; counter++; } }
                if(this.state.showWithOrderObj[1] == "D"){ if(d_old_value != 0){ disease_filter_order = d_old_value; set_for[counter] = "D"; set_value[counter] = d_old_value; counter++; } }
                if(this.state.showWithOrderObj[1] == "I"){ if(i_old_value != 0){ intervention_filter_order = i_old_value; set_for[counter] = "I"; set_value[counter] = i_old_value; counter++; } }

                if(counter == 1){
                    this.changeOrderNumber(set_for[0],set_value[0]);
                }
                if(counter == 2){
                    for(let i=0; i<= 1; i++){
                        if(i == 0){
                            this.changeOrderNumber(set_for[0],set_value[0]);
                        }
                        if(i == 1){
                            
                            this.setState({
                                isLoadingOrder:true,
                            });
                            setTimeout(
                                function() {
                                    this.changeOrderNumber(set_for[1],set_value[1]);
                                }
                                .bind(this),
                                2000
                            );
                            setTimeout(
                                function() {
                                    this.setState({
                                        isLoadingOrder:false,
                                    });
                                }
                                .bind(this),
                                3000
                            );
                        }
                    }
                }
                
            }
            
        }
        if(remove_for === 2){
            if(this.state.temp_var_for_link2 == 1){

                set_for = [];
                set_value = [];
                counter = 0;

                if(this.state.showWithOrderObj[1] == "L"){ if(l_old_value != 0){ location_filter_order = l_old_value; set_for[counter] = "L"; set_value[counter] = l_old_value; counter++; } }
                if(this.state.showWithOrderObj[1] == "S"){ if(s_old_value != 0){ speciality_filter_order = s_old_value; set_for[counter] = "S"; set_value[counter] = s_old_value; counter++; } }
                if(this.state.showWithOrderObj[1] == "D"){ if(d_old_value != 0){ disease_filter_order = d_old_value; set_for[counter] = "D"; set_value[counter] = d_old_value; counter++; } }
                if(this.state.showWithOrderObj[1] == "I"){ if(i_old_value != 0){ intervention_filter_order = i_old_value; set_for[counter] = "I"; set_value[counter] = i_old_value; counter++; } }

                if(this.state.showWithOrderObj[2] == "L"){ if(l_old_value != 0){ location_filter_order = l_old_value; set_for[counter] = "L"; set_value[counter] = l_old_value; counter++; } }
                if(this.state.showWithOrderObj[2] == "S"){ if(s_old_value != 0){ speciality_filter_order = s_old_value; set_for[counter] = "S"; set_value[counter] = s_old_value; counter++; } }
                if(this.state.showWithOrderObj[2] == "D"){ if(d_old_value != 0){ disease_filter_order = d_old_value; set_for[counter] = "D"; set_value[counter] = d_old_value; counter++; } }
                if(this.state.showWithOrderObj[2] == "I"){ if(i_old_value != 0){ intervention_filter_order = i_old_value; set_for[counter] = "I"; set_value[counter] = i_old_value; counter++; } }

                if(counter == 1){
                    this.changeOrderNumber(set_for[0],set_value[0]);
                }
                if(counter == 2){
                    for(let i=0; i<= 1; i++){
                        if(i == 0){
                            this.changeOrderNumber(set_for[0],set_value[0]);
                        }
                        if(i == 1){
                            this.setState({
                                isLoadingOrder:true,
                            });
                            setTimeout(
                                function() {
                                    this.changeOrderNumber(set_for[1],set_value[1]);
                                }
                                .bind(this),
                                2000
                            );
                            setTimeout(
                                function() {
                                    this.setState({
                                        isLoadingOrder:false,
                                    });
                                }
                                .bind(this),
                                3000
                            );
                        }
                    }
                }

            }
            
        }
        if(remove_for === 3){
            if(this.state.temp_var_for_link3 == 1){
                set_for = [];
                set_value = [];
                counter = 0;

                if(this.state.showWithOrderObj[2] == "L"){ if(l_old_value != 0){ location_filter_order = l_old_value; set_for[counter] = "L"; set_value[counter] = l_old_value; counter++; } }
                if(this.state.showWithOrderObj[2] == "S"){ if(s_old_value != 0){ speciality_filter_order = s_old_value; set_for[counter] = "S"; set_value[counter] = s_old_value; counter++; } }
                if(this.state.showWithOrderObj[2] == "D"){ if(d_old_value != 0){ disease_filter_order = d_old_value; set_for[counter] = "D"; set_value[counter] = d_old_value; counter++; } }
                if(this.state.showWithOrderObj[2] == "I"){ if(i_old_value != 0){ intervention_filter_order = i_old_value; set_for[counter] = "I"; set_value[counter] = i_old_value; counter++; } }

                if(this.state.showWithOrderObj[3] == "L"){ if(l_old_value != 0){ location_filter_order = l_old_value; set_for[counter] = "L"; set_value[counter] = l_old_value; counter++; } }
                if(this.state.showWithOrderObj[3] == "S"){ if(s_old_value != 0){ speciality_filter_order = s_old_value; set_for[counter] = "S"; set_value[counter] = s_old_value; counter++; } }
                if(this.state.showWithOrderObj[3] == "D"){ if(d_old_value != 0){ disease_filter_order = d_old_value; set_for[counter] = "D"; set_value[counter] = d_old_value; counter++; } }
                if(this.state.showWithOrderObj[3] == "I"){ if(i_old_value != 0){ intervention_filter_order = i_old_value; set_for[counter] = "I"; set_value[counter] = i_old_value; counter++; } }
                if(counter == 1){
                    this.changeOrderNumber(set_for[0],set_value[0]);
                }
                if(counter == 2){
                    for(let i=0; i<= 1; i++){
                        if(i == 0){
                            this.changeOrderNumber(set_for[0],set_value[0]);
                        }
                        if(i == 1){
                            this.setState({
                                isLoadingOrder:true,
                            });
                            setTimeout(
                                function() {
                                    this.changeOrderNumber(set_for[1],set_value[1]);
                                }
                                .bind(this),
                                2000
                            );
                            setTimeout(
                                function() {
                                    this.setState({
                                        isLoadingOrder:false,
                                    });
                                }
                                .bind(this),
                                3000
                            );
                        }
                    }
                }
            } 
        }
    }

    
    setPlacesRef = (element) => {
        this.placesRef = element;
    }

    modifyInputBlur = () => {
        let original = this.placesRef.handleInputOnBlur;
        this.placesRef.handleInputOnBlur = () => {
            if(this.state.isClearSuggestion === true){
                original();
                this.setState({
                    isClearSuggestion:true,
                    searchResultsAll: [],
                    address: "",
                });
            }
        };
    }

    advanceFilter(advanceFilterstatus) {
        this.setState({
            advanceFilter: advanceFilterstatus,
            
            locations_method: "OR",
            locations_method_status: 0,
            specialities_method: "OR",
            specialities_method_status: 0,
            diseases_method: "OR",
            diseases_method_status: 0,
            interventions_method: "OR",
            interventions_method_status: 0,
            location_redius:-1,
            location_redius_type: "MI",
            
            //show_redius_input: false,
            default_redius: -1,
            default_redius_type: "MI",
            active_location: [],
            searchResults: [],
            searchResultsAll: [],
            searchString: "",
            isClearSuggestion: true,
            
            andOrFilter1: "AND",
            andOrFilter1_status: false,
            andOrFilter2: "AND",
            andOrFilter2_status: false,
            andOrFilter3: "AND",
            andOrFilter3_status: false,
        }, () => {
            //this.getSiteUser();
            this.setTotal_active_filters();
        });
    }

    refreshFilter() {
        let location_filter_order = this.state.location_filter_order;
        let intervention_filter_order = this.state.intervention_filter_order;
        let disease_filter_order = this.state.disease_filter_order;
        let speciality_filter_order = this.state.speciality_filter_order;
        let temp_index = 1;
        let showWithOrderObj = [];
        let showWithOrder = {};

        if(location_filter_order !== 0){ location_filter_order = temp_index; showWithOrder["L"] = temp_index; showWithOrderObj.push("L"); temp_index++;}
        if(speciality_filter_order !== 0){ speciality_filter_order = temp_index;  showWithOrder["S"] = temp_index; showWithOrderObj.push("S"); temp_index++;}
        if(intervention_filter_order !== 0){ intervention_filter_order = temp_index; showWithOrder["I"] = temp_index; showWithOrderObj.push("I"); temp_index++;}
        if(disease_filter_order !== 0){ disease_filter_order = temp_index; showWithOrder["D"] = temp_index; showWithOrderObj.push("D"); temp_index++;}

        this.setState({
            //advanceFilter: advanceFilterstatus,
            location_filter_order: location_filter_order,
            speciality_filter_order: speciality_filter_order,
            disease_filter_order: disease_filter_order,
            intervention_filter_order: intervention_filter_order,
            locations_method: "OR",
            locations_method_status: 0,
            specialities_method: "OR",
            specialities_method_status: 0,
            diseases_method: "OR",
            diseases_method_status: 0,
            interventions_method: "OR",
            interventions_method_status: 0,
            location_redius:-1,
            location_redius_type: "MI",
            
            show_redius_input: false,
            default_redius: -1,
            default_redius_type: "MI",
            active_location: [],
            searchResults: [],
            searchResultsAll: [],
            searchString: "",
            isClearSuggestion: true,
            
            andOrFilter1: "AND",
            andOrFilter1_status: false,
            andOrFilter2: "AND",
            andOrFilter2_status: false,
            andOrFilter3: "AND",
            andOrFilter3_status: false,

            showWithOrder: showWithOrder,
            showWithOrderObj: showWithOrderObj,

            searchTypeL: true,
            searchTypeS: true,
            searchTypeD: true,
            searchTypeI: true,

            show_l_result: false,
            show_s_result: false,
            show_d_result: false,
            show_i_result: false,
        }, () => {
            //this.getSiteUser();
            this.setTotal_active_filters();
        });
    }

    deleteFilter() {
        localStorage.clear();
        this.setState({

            firstCallStatus: 1,
            firstCallStudent: 1,
            firstCallPosition: 1,
            
            half_checklist_dcotor:0,
            half_checklist_student:0,

            docChecked: true,
            stuChecked: false,
            
            selecteDocPosition: [...new Set(this.state.siteUserPosition.map((item) => item.id))],
            selecteStuPosition: [...new Set(this.state.siteUserAcademicYears.map((item) => item.id))],
            doctorPosition: [...new Set(this.state.siteUserPosition.map((item) => item.id))],
            studentPosition: [...new Set(this.state.siteUserAcademicYears.map((item) => item.id))],
            
            activeGoogleSearch: true,
            searchType: "L",

            location: [],
            
            location_filter_order: 0,
            locations_method: "OR",
            locations_method_status: 0,
            location_redius: -1,
            location_redius_type: "MI",
            locationLatLng: [],
            locationChecked: false,

            address: "",
            lat: "",
            long: "",
            
            specialitChecked: false,
            specialities: [],
            speciality_filter_order: 0,
            specialities_method: "OR",
            specialities_method_status: 0,
            specialitiesIds: [],
            
            interventionChecked: false,
            intervention: [],
            intervention_filter_order: 0,
            interventions_method: "OR",
            interventions_method_status: 0,
            interventionIds: [],
            
            diseaseChecked: false,
            diseases: [],
            disease_filter_order: 0,
            diseases_method: "OR",
            diseases_method_status: 0,
            diseasesIds: [],
            
            //advanceFilter: false,
            activeMsgArea: false,
            searchResults: [],
            searchResultsAll: [],
            searchString: "",
            
            count: 0,
            activeSection:false,
            
            
            bookmarkType: "",

            member_count: 0,
            member_count_final: 0,
            msg_price: 0,
            total_siteusers: 0,
            i_value: "",
            default_redius: -1,
            default_redius_type: "MI",
            active_location: [],
            //show_redius_input: false,

            isClearSuggestion: true,

            searchTypeL: true,
            searchTypeS: true,
            searchTypeD: true,
            searchTypeI: true,

            
            andOrFilter1: "AND",
            andOrFilter1_status: false,
            andOrFilter2: "AND",
            andOrFilter2_status: false,
            andOrFilter3: "AND",
            andOrFilter3_status: false,

            showWithOrder: {},
            showWithOrderObj: [],
            total_active_filters: 0,
            orderNumberHtml:[],

            
            temp_var_for_andor1:0,
            temp_var_for_andor2:0,
            temp_var_for_andor3:0,

            temp_var_for_link1:0,
            temp_var_for_link2:0,
            temp_var_for_link3:0,

            show_link1:0,
            show_link2:0,
            show_link3:0,

            show_l_result: false,
            show_s_result: false,
            show_d_result: false,
            show_i_result: false,

            l_old_value: 0,
            s_old_value: 0,
            d_old_value : 0,
            i_old_value: 0,

        }, () => {
            this.getSiteUser();

        });
    }

    setSuggestionFocusOn(){
        this.setState({
            isClearSuggestion: false,
        });
        
    }
    setSuggestionFocusOff(){
        this.setState({
            isClearSuggestion: true,
        });
        
    }
    
    setBookmark() {
        
        const { doctorStatusId, studentStatusId, doctorPosition, studentPosition } = this.state;
        
        localStorage.setItem("half_checklist_dcotor", JSON.stringify(this.state.half_checklist_dcotor));
        localStorage.setItem("half_checklist_student", JSON.stringify(this.state.half_checklist_student));

        localStorage.setItem("advanceFilter", JSON.stringify(this.state.advanceFilter));

        localStorage.setItem("doctorStatusId", JSON.stringify(doctorStatusId));
        localStorage.setItem("doctorPosition", JSON.stringify(doctorPosition));
        localStorage.setItem("docChecked", JSON.stringify(this.state.docChecked));
        localStorage.setItem("selecteDocPosition", JSON.stringify(this.state.selecteDocPosition));

        localStorage.setItem("studentStatusId", JSON.stringify(studentStatusId));
        localStorage.setItem("studentPosition", JSON.stringify(studentPosition));
        localStorage.setItem("selecteStuPosition", JSON.stringify(this.state.selecteStuPosition));
        localStorage.setItem("stuChecked", JSON.stringify(this.state.stuChecked));

        localStorage.setItem("search_type_picker", JSON.stringify(this.state.search_type_picker));
        localStorage.setItem("activeGoogleSearch", JSON.stringify(this.state.activeGoogleSearch));
        localStorage.setItem("searchType", JSON.stringify(this.state.searchType));

        localStorage.setItem("specialitChecked", JSON.stringify(this.state.specialitChecked));
        localStorage.setItem("specialities", JSON.stringify(this.state.specialities));
        localStorage.setItem("speciality_filter_order", JSON.stringify(this.state.speciality_filter_order));
        localStorage.setItem("specialities_method", JSON.stringify(this.state.specialities_method));
        localStorage.setItem("specialities_method_status", JSON.stringify(this.state.specialities_method_status));
        localStorage.setItem("specialitiesIds", JSON.stringify(this.state.specialitiesIds));

        localStorage.setItem("interventionChecked", JSON.stringify(this.state.interventionChecked));
        localStorage.setItem("intervention", JSON.stringify(this.state.intervention));
        localStorage.setItem("intervention_filter_order", JSON.stringify(this.state.intervention_filter_order));
        localStorage.setItem("interventions_method", JSON.stringify(this.state.interventions_method));
        localStorage.setItem("interventions_method_status", JSON.stringify(this.state.interventions_method_status));
        localStorage.setItem("interventionIds", JSON.stringify(this.state.interventionIds));

        localStorage.setItem("diseaseChecked", JSON.stringify(this.state.diseaseChecked));
        localStorage.setItem("diseases", JSON.stringify(this.state.diseases));
        localStorage.setItem("disease_filter_order", JSON.stringify(this.state.disease_filter_order));
        localStorage.setItem("diseases_method", JSON.stringify(this.state.diseases_method));
        localStorage.setItem("diseases_method_status", JSON.stringify(this.state.diseases_method_status));
        localStorage.setItem("diseasesIds", JSON.stringify(this.state.diseasesIds));

        localStorage.setItem("location", JSON.stringify(this.state.location));
        
        localStorage.setItem("location_filter_order", JSON.stringify(this.state.location_filter_order));
        localStorage.setItem("locations_method", JSON.stringify(this.state.locations_method));
        localStorage.setItem("locations_method_status", JSON.stringify(this.state.locations_method_status));
        //localStorage.setItem("location_redius", JSON.stringify(this.state.location_redius));
        //localStorage.setItem("location_redius_type", JSON.stringify(this.state.location_redius_type));

        localStorage.setItem("location_redius", JSON.stringify(-1));
        localStorage.setItem("location_redius_type", JSON.stringify("MI"));

        localStorage.setItem("locationLatLng", JSON.stringify(this.state.locationLatLng));
        localStorage.setItem("locationChecked", JSON.stringify(this.state.locationChecked));

        localStorage.setItem("searchTypeL", JSON.stringify(this.state.searchTypeL));
        localStorage.setItem("searchTypeS", JSON.stringify(this.state.searchTypeS));
        localStorage.setItem("searchTypeD", JSON.stringify(this.state.searchTypeD));
        localStorage.setItem("searchTypeI", JSON.stringify(this.state.searchTypeI));

        
        localStorage.setItem("andOrFilter1", JSON.stringify(this.state.andOrFilter1));
        localStorage.setItem("andOrFilter1_status", JSON.stringify(this.state.andOrFilter1_status));
        localStorage.setItem("andOrFilter2", JSON.stringify(this.state.andOrFilter2));
        localStorage.setItem("andOrFilter2_status", JSON.stringify(this.state.andOrFilter2_status));
        localStorage.setItem("andOrFilter3", JSON.stringify(this.state.andOrFilter3));
        localStorage.setItem("andOrFilter3_status", JSON.stringify(this.state.andOrFilter3_status));

        localStorage.setItem("showWithOrder", JSON.stringify(this.state.showWithOrder));
        localStorage.setItem("showWithOrderObj", JSON.stringify(this.state.showWithOrderObj));
        localStorage.setItem("showWithOrderTemp", JSON.stringify(this.state.showWithOrderTemp));
        
        localStorage.setItem("total_active_filters", JSON.stringify(this.state.total_active_filters));
        //localStorage.setItem("orderNumberHtml", JSON.stringify(this.state.orderNumberHtml));
        
        localStorage.setItem("temp_var_for_link1", JSON.stringify(this.state.temp_var_for_link1));
        localStorage.setItem("temp_var_for_link2", JSON.stringify(this.state.temp_var_for_link2));
        localStorage.setItem("temp_var_for_link3", JSON.stringify(this.state.temp_var_for_link3));

        localStorage.setItem("show_link1", JSON.stringify(this.state.show_link1));
        localStorage.setItem("show_link2", JSON.stringify(this.state.show_link2));
        localStorage.setItem("show_link3", JSON.stringify(this.state.show_link3));

        localStorage.setItem("temp_var_for_andor1", JSON.stringify(this.state.temp_var_for_andor1));
        localStorage.setItem("temp_var_for_andor2", JSON.stringify(this.state.temp_var_for_andor2));
        localStorage.setItem("temp_var_for_andor3", JSON.stringify(this.state.temp_var_for_andor3));
        
        
        this.setState({
            default_redius: -1,
            default_redius_type: "MI",
            active_location: [],
            show_redius_input: false,
            location_redius: -1,
            location_redius_type: "MI",
        }, () => {
            this.getSiteUser();
        });
    }

    setTotal_active_filters(){
        let showWithOrderObj = this.state.showWithOrderObj;
        let total_active_filters = 0;

        showWithOrderObj.map((todo,indexNew) => {
            if(todo === "L" && this.state.locationLatLng.length>0 && this.state.locationChecked == true){
                total_active_filters++;
            }
            if(todo === "S" && this.state.specialitiesIds.length>0 && this.state.specialitChecked == true){
                total_active_filters++;
            }
            if(todo === "D" && this.state.diseasesIds.length>0 && this.state.diseaseChecked == true){
                total_active_filters++;
            }
            if(todo === "I" && this.state.interventionIds.length>0 && this.state.interventionChecked == true){
                total_active_filters++;
            }
        });

        let temp_var_for_andor = 0;
        let temp_var_for_andor1 = this.state.temp_var_for_andor1;
        let temp_var_for_andor2 = this.state.temp_var_for_andor2;
        let temp_var_for_andor3 = this.state.temp_var_for_andor3;
        let temp_var = [];

        let temp_1 = 0; let temp_2 = 0;let temp_3 = 0;
        
        showWithOrderObj.map((todo,indexNew) => {

            if(todo === "L" && this.state.locationLatLng.length>0 && this.state.locationChecked == true){
                temp_var_for_andor++;
            }
            if(todo === "S" && this.state.specialitiesIds.length>0 && this.state.specialitChecked == true){
                temp_var_for_andor++;
            }
            if(todo === "D" && this.state.diseasesIds.length>0 && this.state.diseaseChecked == true){
                temp_var_for_andor++;
            }
            if(todo === "I" && this.state.interventionIds.length>0 && this.state.interventionChecked == true){
                temp_var_for_andor++;
            }

            if(temp_var_for_andor == 1){
                if(temp_1 == 0){
                    temp_var_for_andor1 = indexNew;
                    temp_1++;
                }

            }

            if(temp_var_for_andor == 3){
                if(temp_2 == 0){
                    temp_var_for_andor2 = indexNew-1;
                    temp_2++;
                }
                
            }
            if(temp_var_for_andor == 4){
                if(temp_3 == 0){
                    temp_var_for_andor3 = indexNew-1;
                    temp_3++;
                }
            }
        });
        
        this.setState({
            total_active_filters: total_active_filters,
            temp_var_for_andor1: temp_var_for_andor1,
            temp_var_for_andor2: temp_var_for_andor2,
            temp_var_for_andor3: temp_var_for_andor3,
            
        }, () => {
            this.setLinkOption();
        });
        
    }

    setLinkOption(){
        let link_temp = 0;
        let temm_for_links = [];
        let showWithOrderObj = this.state.showWithOrderObj;

        showWithOrderObj.map((todo,indexNew) => {

            if(todo === "L" && this.state.locationLatLng.length>0 && this.state.locationChecked == true){
                temm_for_links.push(this.state.location_filter_order);
            }
            if(todo === "S" && this.state.specialitiesIds.length>0 && this.state.specialitChecked == true){
                temm_for_links.push(this.state.speciality_filter_order);
            }
            if(todo === "D" && this.state.diseasesIds.length>0 && this.state.diseaseChecked == true){
                temm_for_links.push(this.state.disease_filter_order);
            }
            if(todo === "I" && this.state.interventionIds.length>0 && this.state.interventionChecked == true){
                temm_for_links.push(this.state.intervention_filter_order);
            }
        });


        let temp_var_for_link1 = this.state.temp_var_for_link1;
        let temp_var_for_link2 = this.state.temp_var_for_link2;
        let temp_var_for_link3 = this.state.temp_var_for_link3;
        let show_link1 = this.state.show_link1;
        let show_link2 = this.state.show_link2;
        let show_link3 = this.state.show_link3;


        temm_for_links.map((orderFor,indexNew) => {
            if(indexNew == 1){
                if(temm_for_links[0] == temm_for_links[1]){
                    if(show_link1 === 0){
                        temp_var_for_link1 =1;
                        show_link1 = 1;
                    }
                }else{
                    temp_var_for_link1 =0;
                    show_link1 = 0;
                }
            }
            if(indexNew == 2){
                if(temm_for_links[1] == temm_for_links[2]){
                    if(show_link2 === 0){
                        temp_var_for_link2 =1;
                        show_link2 = 1;
                    }
                }else{
                    temp_var_for_link2 =0;
                    show_link2 = 0;
                }
            }

            if(indexNew == 3){
                if(temm_for_links[2] == temm_for_links[3]){
                    if(show_link3 === 0){
                        temp_var_for_link3 =1;
                        show_link3 = 1;
                    }
                }else{
                    temp_var_for_link3 =0;
                    show_link3 = 0;
                }
            }
        });

        this.setState({
            temp_var_for_link3:temp_var_for_link3,
            temp_var_for_link2:temp_var_for_link2,
            temp_var_for_link1:temp_var_for_link1,
            showWithOrderObj:showWithOrderObj,
            show_link3: show_link3,
            show_link2: show_link2,
            show_link1: show_link1,
        }, () => {
            this.getSiteUser();
        });

        
    }
    
    changeOrderNumber(setFor,value){
        let location_filter_order = this.state.location_filter_order;
        let speciality_filter_order = this.state.speciality_filter_order;
        let disease_filter_order = this.state.disease_filter_order;
        let intervention_filter_order = this.state.intervention_filter_order;

        let l_old_value = this.state.l_old_value;
        let s_old_value = this.state.s_old_value;
        let d_old_value = this.state.d_old_value;
        let i_old_value = this.state.i_old_value;

        let temp_var = 0;
        let showWithOrder = this.state.showWithOrder;

        let showWithOrderObj = this.state.showWithOrderObj;
        
        const showWithOrderOld = this.state.showWithOrder;
        let last_order_value = showWithOrderOld[setFor];
        let neworder = [];
        let added_new_order = 0;
        let index_Found = 0;
        let orderedArray = [];

        
        let isChangeobj = true;
        if(showWithOrder[setFor] == value){
            ////console.log("Do nothing 1");
            
        }else{

            
            showWithOrder[setFor] = value;

            showWithOrderObj.map((todo,indexNew) => {
                let item = todo;
                neworder.push({item: item,value: showWithOrder[item] });
            });
            neworder = neworder.sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
            neworder.map((todo,indexNew) => {
                
                orderedArray.push(todo.item);
            });

            let temp_array = [];
            orderedArray.map((lsdi,indexNew) => {
                temp_array.push(lsdi.item);
            });

            if(value < 5){
                if(setFor === "L"){
                    temp_var = value;
                    location_filter_order = temp_var;
                    
                }
                if(setFor === "S"){
                    temp_var = value;
                    speciality_filter_order = temp_var;
                    
                }
                if(setFor === "D"){
                    temp_var = value;
                    disease_filter_order = temp_var;
                    
                }
                if(setFor === "I"){
                    temp_var = value;
                    intervention_filter_order = temp_var;
                    
                }
            }
            
            this.setState({
                showWithOrderObj: orderedArray,
                location_filter_order: location_filter_order,
                speciality_filter_order: speciality_filter_order,
                disease_filter_order: disease_filter_order,
                intervention_filter_order: intervention_filter_order,
                //showWithOrderOld: showWithOrderOld,
                showWithOrder: showWithOrder,
                
                last_order_for: setFor,
                last_order_value: last_order_value,
                
            }, () => {
                this.setTotal_active_filters();
                //this.getSiteUser();
            });
        }
    }

    
    checkLocationmatch(singleLatLen){
        
        let latlenArray = this.state.locationLatLng;
        let returnValue = false;
        
        if(latlenArray.length && singleLatLen){
            latlenArray.forEach((item)=>{
                if(singleLatLen.lat === item.lat && singleLatLen.lng === item.lng){
                    returnValue = true;
                }
            });    
        }
        return returnValue;
    }

    checkLocationdelete(singleLatLen){
        let latlenArray = this.state.location;
        let returnValue = false;
        
        if(latlenArray.length && singleLatLen){
            latlenArray.forEach((item)=>{
                let location_latlen = item.latLng;
                if(singleLatLen.lat === location_latlen.lat && singleLatLen.lng === location_latlen.lng){
                    returnValue = true;
                }
            });    
        }
        return returnValue;
    }

    doctorStatus(status) {
        
        if (status === true) {
            this.setState({
                docChecked: true,
                doctorStatusId: this.state.siteUserStatus[0].id,
                doctorPosition: [...new Set(this.state.siteUserPosition.map((item) => item.id))],
                selecteDocPosition: [...new Set(this.state.siteUserPosition.map((item) => item.id))],
                half_checklist_dcotor: 0,
                
            }, () => {
                this.getSiteUser();
            });
        } else {
            this.setState({
                docChecked: false,
                doctorStatusId: "",
                //doctorPosition: [],
                selecteDocPosition: [],
            }, () => {
                this.getSiteUser();
            });
        }
    }

    studentStatus(status) {
        if (status === true) {
            this.setState({
                stuChecked: status,
                studentStatusId: this.state.siteUserStatus[1].id,
                studentPosition: [...new Set(this.state.siteUserAcademicYears.map((item) => item.id))],
                selecteStuPosition: [...new Set(this.state.siteUserAcademicYears.map((item) => item.id))],
                half_checklist_student: 0,
            }, () => {
                this.getSiteUser();
            });
        } else {
            this.setState({
                stuChecked: false,
                studentStatusId: "",
                //studentPosition: [],
                selecteStuPosition: [],
            }, () => {
                this.getSiteUser();
            });
        }
    }

    locationStatus(status) {
        
        if (status === true) {
            this.setState({
                locationChecked: true,
                locationLatLng: this.state.locationLatLng
            }, () => {
                
                this.setTotal_active_filters();
            });
        } else {
            let location_redius = this.state.default_redius;
            let location_redius_type = this.state.default_redius_type;
            
            this.setState({
                active_location: [],
                location_redius: location_redius,
                location_redius_type: location_redius_type,
                show_redius_input: false,
                locationChecked: false,
                //locationLatLng: [],
            }, () => {
                //this.getSiteUser();
                this.setTotal_active_filters();
            });
        }

        
    }

    specialitStatus(status) {
        
        if (status === true) {
            this.setState({
                specialitChecked: true,
                specialitiesIds: this.state.specialities.map((item) => item.id)
            }, () => {
                //this.getSiteUser();
                this.setTotal_active_filters();
            });
        } else {
            this.setState({
                specialitChecked: false,
                //specialitiesIds: [],
            }, () => {
                //this.getSiteUser();
                this.setTotal_active_filters();
            });
        }
        
    }

    diseasesStatus(status) {
        
        if (status === true) {
            this.setState({
                diseaseChecked: true,
                diseasesIds: this.state.diseases.map((item) => item.id)
            }, () => {
                //this.getSiteUser();
                this.setTotal_active_filters();
            });
        } else {
            this.setState({
                diseaseChecked: false,
                //diseasesIds: [],
            }, () => {
                //this.getSiteUser();
                this.setTotal_active_filters();
            });
        }
        
    }

    interventionStatus(status) {
        
        if (status === true) {
            this.setState({
                interventionChecked: true,
                interventionIds: this.state.intervention.map((item) => item.id)
            }, () => {
                //this.getSiteUser();
                this.setTotal_active_filters();
            });
        } else {
            this.setState({
                interventionChecked: false,
                //interventionIds: [],
            }, () => {
                //this.getSiteUser();
                this.setTotal_active_filters();
            });
        }
        
    }

    checkDoctorsList(selecteDocPosition){
        let array1 = this.state.doctorPosition.sort((a, b) => (a > b) ? 1 : -1);
        let array2 = selecteDocPosition.sort((a, b) => (a > b) ? 1 : -1);
        if(JSON.stringify(array1) !== JSON.stringify(array2)){
            this.setState({
                half_checklist_dcotor: 1,
            });
        }else{
            this.setState({
                half_checklist_dcotor: 0,
            });
        }
    }

    checkStudentList(selecteStuPosition){
        let array1 = this.state.studentPosition.sort((a, b) => (a > b) ? 1 : -1);
        let array2 = selecteStuPosition.sort((a, b) => (a > b) ? 1 : -1);
        if(JSON.stringify(array1) !== JSON.stringify(array2)){
            this.setState({
                half_checklist_student: 1,
            });
        }else{
            this.setState({
                half_checklist_student: 0,
            });
        }
    }

    selectDoctorPosition(id) {
        var s_arr = this.state.selecteDocPosition;

        if (s_arr.includes(id)) {
            this.removeDoctorPosition(id);
        } else {
            s_arr.push(id);
            const getVal = s_arr.filter((item) => item);

            this.checkDoctorsList(getVal);

            this.setState({
                //doctorPosition: s_arr,
                selecteDocPosition: getVal
            }, () => {
                this.getSiteUser();
                
                if (this.state.selecteDocPosition.length !== 0) {
                    this.setState({
                        docChecked: true,
                        doctorStatusId: this.state.siteUserStatus[0].id
                    });
                } else {
                    this.setState({
                        docChecked: false,
                    });
                }
            });
        }
    }

    selectStudentPosition(id) {
        var s_arr = this.state.selecteStuPosition;
        if (s_arr.includes(id)) {
            this.removeStudentPosition(id);
        }else{
            s_arr.push(id);
            const getVal = s_arr.filter((item) => item);

            this.checkStudentList(getVal);

            this.setState({
                //studentPosition: s_arr,
                selecteStuPosition: getVal,
            }, () => {
                this.getSiteUser();
                if (this.state.selecteStuPosition.length !== 0) {
                    this.setState({
                        stuChecked: true,
                        studentStatusId: this.state.siteUserStatus[1].id
                    });
                } else {
                    this.setState({
                        stuChecked: false,
                    });
                }
            });
        }
        
        
    }

    removeDoctorPosition(id) {
        var s_arr = this.state.selecteDocPosition;
        const getVal = s_arr.filter(e => e !== id);
        this.checkDoctorsList(getVal);
        this.setState({
            //doctorPosition: getVal,
            selecteDocPosition: getVal
        }, () => {
            this.getSiteUser();
            
            if (this.state.selecteDocPosition.length !== 0) {
                this.setState({
                    doctorStatusId: this.state.siteUserStatus[0].id
                });
            }
        });
        
    }

    removeStudentPosition(id) {
        var s_arr = this.state.selecteStuPosition;
        const getVal = s_arr.filter(e => e !== id);
        this.checkStudentList(getVal);
        this.setState({
            //studentPosition: getVal,
            selecteStuPosition: getVal
        }, () => {
            this.getSiteUser();
            if (this.state.selecteStuPosition.length !== 0) {
                this.setState({
                    studentStatusId: this.state.siteUserStatus[1].id
                });
            }
        });
    }

    getSiteUser() {
        
        this.ifTwoFilter();
        this.ifThreeFilter();
        this.ifFourFilter();
        const { doctorStatusId, studentStatusId } = this.state;

        const allParams = {};

        let activeLSDI = [];
        let showWithOrderObj = this.state.showWithOrderObj;
        showWithOrderObj.map((todo,indexNew) => {
            if(todo === "L" && this.state.locationLatLng.length>0 && this.state.locationChecked == true){
                activeLSDI.push("L");
            }
            if(todo === "S" && this.state.specialitiesIds.length>0 && this.state.specialitChecked == true){
                activeLSDI.push("S");
            }
            if(todo === "D" && this.state.diseasesIds.length>0 && this.state.diseaseChecked == true){
                activeLSDI.push("D");
            }
            if(todo === "I" && this.state.interventionIds.length>0 && this.state.interventionChecked == true){
                activeLSDI.push("I");
            }
        });

        
        allParams["student_status_id"] = studentStatusId;
        allParams["doctor_status_id"] = doctorStatusId;
        allParams["student_status_id"] = studentStatusId;
        allParams["doctors_position"] = this.state.selecteDocPosition.toString();
        allParams["students_position"] = this.state.selecteStuPosition.toString();

        allParams["activeLSDI"] = activeLSDI;
        

        
        allParams["speciality_filter"] = {
            specialitChecked: this.state.specialitChecked,
            specialities: this.state.specialitiesIds,
            specialities_method: this.state.specialities_method
        };
        
        allParams["intervention_filter"] = {
            interventionChecked: this.state.interventionChecked,
            interventions: [...new Set(this.state.interventionIds)],
            interventions_method: this.state.interventions_method
        };
        
        allParams["disease_filter"] = {
            diseaseChecked: this.state.diseaseChecked,
            diseases: [...new Set(this.state.diseasesIds)],
            diseases_method: this.state.diseases_method
        };
        
        allParams["location_filter"] = {
            locationChecked: this.state.locationChecked,
            locations: [...new Set(this.state.locationLatLng)],
            //locations: this.state.location,
            redius: this.state.location_redius,
            redius_type: this.state.location_redius_type,
            locations_method: this.state.locations_method,
        };
        

        allParams["is_advance_filter"] = this.state.advanceFilter;

        let showWithOrderTemp = this.state.showWithOrder;

        

        allParams["location_filter_order"] = this.state.location_filter_order;
        allParams["intervention_filter_order"] = this.state.intervention_filter_order;
        allParams["speciality_filter_order"] = this.state.speciality_filter_order;
        allParams["disease_filter_order"] = this.state.disease_filter_order;

        allParams["andOrFilter1"] = this.state.andOrFilter1;
        allParams["andOrFilter2"] = this.state.andOrFilter2;
        allParams["andOrFilter3"] = this.state.andOrFilter3;
        
        allParams["linkFilter1"] = this.state.temp_var_for_link1;
        allParams["linkFilter2"] = this.state.temp_var_for_link2;
        allParams["linkFilter3"] = this.state.temp_var_for_link3;

        
        this.setState({
            isLoadingCount: true,
            
        });
        
        this.props.dispatch(onCallActions.getSiteUsers(allParams));


    }

    BookmarkHandler(bookmarkType) {
        if (bookmarkType === "B") {
            //this.setBookmark();
            this.setState({
                bookmarkType: bookmarkType,
            }, () => {
                this.setBookmark();
            });
        }
        if (bookmarkType === "D") {
            //this.deleteFilter();
            this.setState({
                bookmarkType: bookmarkType,
            }, () => {
                this.deleteFilter();
            });
        }
        if (bookmarkType === "R") {
            //this.refreshFilter();
            this.setState({
                bookmarkType: bookmarkType,
            }, () => {
                this.refreshFilter();
            });
        }

    }

    setNewSearchType(){
        
        if(this.state.searchTypeL === true){
            
            this.setState({
                activeGoogleSearch: true
            });
        }else if(this.state.searchTypeL === false && this.state.searchTypeS === false && this.state.searchTypeD === false && this.state.searchTypeI === false){
            swal("At least one search category needs to be selected!");
        }else{
            this.setState({
                activeGoogleSearch: false
            });
        }

        

    }

    checkSearchHandler(searchType){
        
        let typeCounter = 0;
        let lsdi_counter = 0;
        if(this.state.searchTypeL === false){
            lsdi_counter++;
        } 
        if(this.state.searchTypeS === false){
            lsdi_counter++;
        } 
        if(this.state.searchTypeD === false){
            lsdi_counter++;
        }
        if(this.state.searchTypeI === false){
            lsdi_counter++;
        }

        let show_alert = false;
        if(searchType === "L" && this.state.searchTypeL === true && lsdi_counter > 2){
            show_alert = true;
        } 
        if(searchType === "S" && this.state.searchTypeS === true && lsdi_counter > 2){
            show_alert = true;
        } 
        if(searchType === "D" && this.state.searchTypeD === true && lsdi_counter > 2){
            show_alert = true;
        }
        if(searchType === "I" && this.state.searchTypeI === true && lsdi_counter > 2){
            show_alert = true;
        }



        if(show_alert === true){
            swal("At least one search category needs to be selected!");
        }else{
            if(searchType === "L"){
                if(this.state.searchTypeL == true){
                    typeCounter++;
                    this.setState({
                        searchTypeL: false,
                        activeGoogleSearch: false,
                    }, () => {
                        this.setNewSearchType();
                    });
                }else{
                    this.setState({
                        searchTypeL: true,
                        activeGoogleSearch:true,
                    }, () => {
                        this.setNewSearchType();
                    });
                }
            }
            if(searchType === "S"){
                if(this.state.searchTypeS === true){
                    typeCounter++;
                    this.setState({
                        searchTypeS: false,
                    }, () => {
                        this.setNewSearchType();
                    });
                }else{
                    this.setState({
                        searchTypeS: true,
                    }, () => {
                        this.setNewSearchType();
                    });
                }
            }
            if(searchType === "D"){
                if(this.state.searchTypeD === true){
                    typeCounter++;
                    this.setState({
                        searchTypeD: false,
                    }, () => {
                        this.setNewSearchType();
                    });
                }else{
                    this.setState({
                        searchTypeD: true,
                    }, () => {
                        this.setNewSearchType();
                    });
                }
            }
            if(searchType === "I"){
                if(this.state.searchTypeI === true){
                    typeCounter++;
                    this.setState({
                        searchTypeI: false,
                    }, () => {
                        this.setNewSearchType();
                    });
                }else{
                    this.setState({
                        searchTypeI: true,
                    }, () => {
                        this.setNewSearchType();
                    });
                }
            }
        }
    }
    

    SearchHandler(searchType) {
        this.checkSearchHandler(searchType);

        this.setState({
            address: "",
            searchString: "",
            searchResultsAll: [],
        });
    }
    
    activeMsgArea() {
        if(this.state.member_count === 0 && this.state.siteUsers){
            this.setState({
                member_count: this.state.siteUsers.total_siteusers
            }, () => {
                this.updatePriceFromMemberCount();
            });    
        }
        this.setState({
            activeMsgArea: !this.state.activeMsgArea
        });
    }

    selectAllMember(){
        let total_user = this.state.siteUsers.total_siteusers;
        let free_msg_per = 0;
        if(total_user > 100){
            let single_per = (total_user/100);
            free_msg_per = (100/single_per);
        }else if(total_user >= 0 && total_user < 101 ){
            free_msg_per = 100;
        }
        this.setState({
            free_msg_per:free_msg_per,
            member_count: total_user,
            member_count_final:total_user,
        }, () => {
            this.updatePriceFromMemberCount();
        }); 
        
    }

    setFreeMemberCount() {
        
        let total_siteusers = this.state.total_siteusers;
        if(parseInt(total_siteusers) > 100){
            this.setState({
                member_count: 100,
                msg_price: 0,
                free_msg_per: 100,
                member_count_final: 100,
            });
        }else{
            this.setState({
                member_count: this.state.total_siteusers,
                msg_price: 0,
                free_msg_per:100,
                member_count_final: this.state.total_siteusers,
            });
        }
            
    }

    
    fillShowWithOrderObj(value){

        let showWithOrderObj = this.state.showWithOrderObj;
        let showWithOrder = this.state.showWithOrder;
        let isAddValue = 0;

        let location_filter_order = this.state.location_filter_order;
        let speciality_filter_order = this.state.speciality_filter_order;
        let disease_filter_order = this.state.disease_filter_order;
        let intervention_filter_order = this.state.intervention_filter_order;

        let l_old_value = this.state.l_old_value;
        let s_old_value = this.state.s_old_value;
        let d_old_value = this.state.d_old_value;
        let i_old_value = this.state.i_old_value;
        let new_order = 0;
        let orderArray = [];
        if (showWithOrderObj.includes(value)){}else{isAddValue = 1; }

        orderArray.push(location_filter_order);
        orderArray.push(speciality_filter_order);
        orderArray.push(disease_filter_order);
        orderArray.push(intervention_filter_order);

        
        if(isAddValue === 1){
            
            if(showWithOrderObj.length == 0){
                showWithOrder[value] = 1;
                new_order = 1;
            }else{
                let max_value = Math.max(...orderArray);
                if(max_value == 4){
                    showWithOrder[value] = 4;
                    new_order = 4;
                }else{
                    showWithOrder[value] = max_value+1;
                    new_order = max_value+1;
                }
               
            }

            if(value == "L"){ location_filter_order = new_order; l_old_value = new_order;}
            if(value == "S"){ speciality_filter_order = new_order; s_old_value = new_order;}
            if(value == "D"){ disease_filter_order = new_order; d_old_value = new_order;}
            if(value == "I"){ intervention_filter_order = new_order; i_old_value = new_order;}

            showWithOrderObj.push(value);
        }
        
        this.setState({
            showWithOrderObj: showWithOrderObj,
            location_filter_order: location_filter_order,
            speciality_filter_order: speciality_filter_order,
            disease_filter_order: disease_filter_order,
            intervention_filter_order: intervention_filter_order,
            showWithOrder: showWithOrder,
            l_old_value: l_old_value,
            s_old_value: s_old_value,
            d_old_value: d_old_value,
            i_old_value: i_old_value,
        }, () => {
            this.setTotal_active_filters();
        });
        
    }
    handleOnSelecteSearch(item) {
        
        if (this.state.searchType === "S" || item.item_type === "S") {
            
            let itemArray = this.state.specialities;
            const ids = this.state.specialitiesIds;
            if (!ids.includes(item.id)) {
                itemArray.push({ id: item.id, name: item.name });
                ids.push(item.id);
            }

            
            this.setState({
                specialitChecked: true,
                specialities: itemArray,
                specialitiesIds: ids,
                searchString: "",
            }, () => {
                //this.getSiteUser();
                this.fillShowWithOrderObj("S");
            });
        }
        if (this.state.searchType === "I" || item.item_type === "I") {
            
            let intervention = this.state.intervention;
            const ids = this.state.interventionIds;
            if (!ids.includes(item.id)) {
                intervention.push({ id: item.id, name: item.name });
                ids.push(item.id);
            }

            
            this.setState({
                interventionChecked: true,
                intervention: intervention,
                interventionIds: ids,
                searchString: "",
            }, () => {
                //this.getSiteUser();
                this.fillShowWithOrderObj("I");
            });
        }
        if (this.state.searchType === "D" || item.item_type === "D") {
            
            let diseases = this.state.diseases;
            const ids = this.state.diseasesIds;
            if (!ids.includes(item.id)) {
                diseases.push({ id: item.id, name: item.name });
                ids.push(item.id);
            }
            

            this.setState({
                diseaseChecked: true,
                diseases: diseases,
                diseasesIds: ids,
                searchString: "",
            }, () => {
                //this.getSiteUser();
                this.fillShowWithOrderObj("D");
            });
        }
        if (this.state.searchType === "ALL" && item.item_type === "L") {
            
            this.setState(
                {
                    lat: item.lat,
                    long: item.lng,
                    address: item.name,
                    
                }, () => {
                    let Selectedlocation = this.state.location;
                    let locationLatLngTemp = this.state.locationLatLng;
                    let newLetLng = {};
                    

                    newLetLng["lat"] = item.lat;
                    newLetLng["lng"] = item.lng;
                    newLetLng["redius"] = this.state.default_redius;
                    newLetLng["redius_type"] = this.state.default_redius_type;
                    newLetLng["local_search"] = 1;

                    
                    
                    if (!this.checkLocationmatch(newLetLng)) {
                        Selectedlocation.push({ id: this.state.count, latLng: newLetLng, address: item.name });
                        locationLatLngTemp.push(newLetLng);
                    }
                    
                    this.setState({
                        count: this.state.count + 1,
                        locationChecked: true,
                        location: Selectedlocation,
                        
                        locationLatLng: locationLatLngTemp,
                        address: "",
                        searchString: "",
                    }, () =>{
                        //this.getSiteUser();
                        this.fillShowWithOrderObj("L");
                    });


                }
            );
        }
        
        
    }

    handleOnSelecteAll(item) {
        this.setState({
            isClearSuggestion:true,
            searchResultsAll: [],
            address: "",
        });

        this.placesRef.setState({
            suggestions: [],
        });

        if (item.item_type === "S") {
            let itemArray = this.state.specialities;
            const ids = this.state.specialitiesIds;
            if (!ids.includes(item.id)) {
                itemArray.push({ id: item.id, name: item.name });
                ids.push(item.id);
            }

            this.setState({
                specialitChecked: true,
                specialities: itemArray,
                specialitiesIds: ids,
                searchResultsAll: [],
                address: "",

            }, () => {
                //this.getSiteUser();
                this.fillShowWithOrderObj("S");
            });
        }
        if (item.item_type === "I") {
            
            let intervention = this.state.intervention;
            const ids = this.state.interventionIds;
            if (!ids.includes(item.id)) {
                intervention.push({ id: item.id, name: item.name });
                ids.push(item.id);
            }

            this.setState({
                interventionChecked: true,
                intervention: intervention,
                interventionIds: ids,
                
            }, () => {
                //this.getSiteUser();
                this.fillShowWithOrderObj("I");
            });
        }
        if (item.item_type === "D") {
            
            let diseases = this.state.diseases;
            const ids = this.state.diseasesIds;
            if (!ids.includes(item.id)) {
                diseases.push({ id: item.id, name: item.name });
                ids.push(item.id);
            }
            
            this.setState({
                diseaseChecked: true,
                diseases: diseases,
                diseasesIds: ids,
                
            }, () => {
                //this.getSiteUser();
                this.fillShowWithOrderObj("D");
            });
        }
    }

    RemoveLatLng(latLng){
        let latlenArray = this.state.locationLatLng;
        let latlenArraynew = [];
        
        if(latlenArray.length && latLng){
            latlenArray.forEach((item)=>{
                if(latLng.lat === item.lat && latLng.lng === item.lng){
                    //Comment
                }else{
                    latlenArraynew.push(item);
                }
            });    
        }
        return latlenArraynew;
    }

    RemoveLocation(latLng){
        let locationArray = this.state.location;
        let locationArraynew = [];
        
        if(locationArray.length && latLng){
            locationArray.forEach((item)=>{
                let location_latlen = item.latLng;
                if(latLng.lat === location_latlen.lat && latLng.lng === location_latlen.lng){
                    //console.log(': ');
                }else{
                    locationArraynew.push(item);
                }
            });    
        }
        return locationArraynew;
    }

    AdvanceFilterLocation(value) {

        
        if(this.state.locationChecked === true){
            let selectedlatlen = this.state.locationLatLng;
            const newId = value;
            
            if (!this.checkLocationmatch(newId)) {
                selectedlatlen.push(newId);
                
            } else {
                
                selectedlatlen = this.RemoveLatLng(newId);
                let location_redius = this.state.default_redius;
                let location_redius_type = this.state.default_redius_type;
                this.setState({
                    active_location: [],
                    location_redius: location_redius,
                    location_redius_type: location_redius_type,
                    show_redius_input: false,
                });
            }
            
            this.setState({
                locationLatLng: selectedlatlen
            }, () => {
                
                //this.HideRediusInput();
                this.setTotal_active_filters();
            });
        }
    }

    FilterLocationRedius(latLng) {
        if(this.state.locationChecked === true && this.checkLocationmatch(latLng)){
            let locationArray = this.state.location;
            
            let location_redius = this.state.default_redius;
            let location_redius_type = this.state.default_redius_type;
            let active_location = this.state.active_location;
            if(active_location.lat === latLng.lat && active_location.lng === latLng.lng){
                this.setState({
                    active_location: [],
                    location_redius: location_redius,
                    location_redius_type: location_redius_type,
                    show_redius_input: false,
                });
            }else{
                if(locationArray.length && latLng){
                    locationArray.forEach((item)=>{
                        let location_latlen = item.latLng;
                        if(latLng.lat === location_latlen.lat && latLng.lng === location_latlen.lng){
                            location_redius = location_latlen.redius;
                            location_redius_type = location_latlen.redius_type;
                            
                            this.setState({
                                active_location: latLng,
                                location_redius: location_redius,
                                location_redius_type: location_redius_type,
                                show_redius_input: true,
                            });
                            
                        }
                    });    
                }
            }
        }
        
    }

    setActiveLocationRedius(){
        let locationArray = this.state.location;
        let locationArrayNew = [];
        let selectedlatlen = this.state.locationLatLng;
        let selectedlatlenNew = [];
        let latLng = this.state.active_location;

        if(locationArray.length && latLng){
            locationArray.forEach((item)=>{
                let location_latlen = item.latLng;
                if(latLng.lat === location_latlen.lat && latLng.lng === location_latlen.lng){
                    item.latLng.redius = this.state.location_redius;
                    item.latLng.redius_type = this.state.location_redius_type;
                }
                locationArrayNew.push(item);
            });  

            if(selectedlatlen.length){
                selectedlatlen.forEach((item)=>{
                    
                    if(latLng.lat === item.lat && latLng.lng === item.lng){
                        item.redius = this.state.location_redius;
                        item.redius_type = this.state.location_redius_type;
                    }
                    
                    selectedlatlenNew.push(item);
                });  

                this.setState({
                    locationLatLng: selectedlatlenNew,
                    location: locationArrayNew,
                }, () => {
                    this.getSiteUser();
                });
            }

             
        }
    }

    DeleteFilterLocation(value) {
        let selectedlatlen = this.state.locationLatLng;
        let location = this.state.location;
        let item_checked = this.state.locationChecked;
        const newId = value;
        
        if (this.checkLocationdelete(newId)) {
            selectedlatlen = this.RemoveLatLng(newId);
            location = this.RemoveLocation(newId);
            if(selectedlatlen.length == 0){ item_checked = false; }
        }
        
        this.setState({
            locationLatLng: selectedlatlen,
            location: location,
            locationChecked: item_checked,
        }, () => {
            this.setTotal_active_filters();
        });
        
    }

    AdvanceFilterSpeciality(value) {
        if(this.state.specialitChecked === true){
            const arr = this.state.specialitiesIds;
            const newId = value;

            if (!arr.includes(newId)) {
                arr.push(newId);
            } else {
                arr.splice(arr.indexOf(newId), 1);
            }
            this.setState({
                specialitiesIds: arr,
            }, () => {
                this.setTotal_active_filters();
            });
        }
        

        
    }

    DeleteFilterSpeciality(value) {
        let arr = this.state.specialitiesIds;
        let specialities = this.state.specialities;
        const newId = value;
        let item_checked = this.state.specialitChecked;
        let remove_id = 0;
        if(specialities.length && newId){
            specialities.forEach((item)=>{
                if(item.id == newId){
                    remove_id = newId;
                }
            });
        }

        if(remove_id > 0){
            specialities = specialities.filter(e => e.id !== newId);
            if (arr.includes(newId)) {
                arr.splice(arr.indexOf(newId), 1);
            }
        }
        if(arr.length == 0){ item_checked = false; }

        
        this.setState({
            specialitiesIds: arr,
            specialities: specialities,
            specialitChecked: item_checked,
        }, () => {
            this.setTotal_active_filters();
        });
    }

    AdvanceFilterDisease(value) {
        if(this.state.diseaseChecked === true){
            const arr = this.state.diseasesIds;
            const newId = value;

            if (!arr.includes(newId)) {
                arr.push(newId);
            } else {
                arr.splice(arr.indexOf(newId), 1);
            }
            this.setState({
                diseasesIds: arr
            }, () => {
                this.setTotal_active_filters();
            });
        }
    }

    DeleteFilterDisease(value) {
        let arr = this.state.diseasesIds;
        let diseases = this.state.diseases;
        const newId = value;
        let item_checked = this.state.diseaseChecked;
        let remove_id = 0;
        
        if(diseases.length && newId){
            diseases.forEach((item)=>{
                if(item.id == newId){
                    remove_id = newId;
                }
            });    
        }

        if(remove_id>0){
            diseases = diseases.filter(e => e.id !== newId);
            if (arr.includes(newId)) {
                arr.splice(arr.indexOf(newId), 1);
            }
        }
        if(arr.length == 0){ item_checked = false; }

        this.setState({
            diseasesIds: arr,
            diseases: diseases,
            diseaseChecked: item_checked,
        }, () => {
            this.setTotal_active_filters();
        });
        
    }

    AdvanceFilterIntervention(value) {
        if(this.state.interventionChecked === true){
            const arr = this.state.interventionIds;
            const newId = value;

            if (!arr.includes(newId)) {
                arr.push(newId);
            } else {
                arr.splice(arr.indexOf(newId), 1);
            }
            this.setState({
                interventionIds: arr
            }, () => {
                this.setTotal_active_filters();
            });
        }
        
        
    }

    DeleteFilterIntervention(value) {
        let arr = this.state.interventionIds;
        let intervention = this.state.intervention;
        const newId = value;
        let item_checked = this.state.interventionChecked;
        let remove_id = 0;;
        
        if(intervention.length && newId){
            intervention.forEach((item)=>{
                if(item.id == newId){
                    remove_id = newId;
                }
            });    
        }
        if(remove_id>0){
            intervention = intervention.filter(e => e.id !== newId);
            if (arr.includes(newId)) {
                arr.splice(arr.indexOf(newId), 1);
            }
        }
        if(arr.length === 0){ item_checked = false; }

        this.setState({
            interventionIds: arr,
            intervention: intervention,
            interventionChecked: item_checked,
        }, () => {
            this.setTotal_active_filters();
        });
        
    }

    handleChange = address => {
        this.setState({ address ,activeSection:false});
        this.props.dispatch(onCallActions.getSearchAll({ search_text: address, searchTypeI: this.state.searchTypeI, searchTypeD: this.state.searchTypeD, searchTypeS: this.state.searchTypeS, searchTypeL: this.state.searchTypeL }));
        
    };


    handleSelect = address => {
        this.setState({
            searchResultsAll: [],
            isClearSuggestion:true,
        
        });
        geocodeByAddress(address)
            .then(results => 
                getLatLng(results[0],
                    
                    this.setState({
                        address_components: results[0].address_components,
                    }),
                ),

            )
            .then(latLng =>
                this.setState({
                    lat: latLng.lat,
                    long: latLng.lng,
                    address: address,
                    
                    activeSection:false,
                    
                }, () => {
                    let location = this.state.location;

                    let selectedlatlen = this.state.locationLatLng;
                    let newLetLng = {};
                    
                    newLetLng["lat"] = latLng.lat;
                    newLetLng["lng"] = latLng.lng;
                    newLetLng["redius"] = this.state.default_redius;
                    newLetLng["redius_type"] = this.state.default_redius_type;
                    let postal_code = this.state.address_components.find(c => c.types.includes("postal_code")) || {};
                    let route = this.state.address_components.find(c => c.types.includes("route")) || {};
                    let postal_town = this.state.address_components.find(c => c.types.includes("postal_town")) || {};
                    let city = this.state.address_components.find(c => c.types.includes("administrative_area_level_2")) || {};
                    let state = this.state.address_components.find(c => c.types.includes("administrative_area_level_1")) || {};

                    newLetLng["postal_code"] = postal_code.long_name ? postal_code.long_name :"";
                    newLetLng["route"] = route.long_name ? route.long_name :"";
                    newLetLng["postal_town"] = postal_town.long_name ? postal_town.long_name : "";
                    newLetLng["city"] = city.long_name ? city.long_name : "";
                    newLetLng["state"] = state.long_name ? state.long_name : "";
                    newLetLng["local_search"] = 0;

                    if (!this.checkLocationmatch(latLng)) {
                        location.push({ id: this.state.count, latLng: newLetLng, address: address });
                        selectedlatlen.push(newLetLng);
                    }
                    
                    this.setState({
                        count: this.state.count + 1,
                        locationChecked: true,
                        location: location,
                        locationLatLng: selectedlatlen,
                        address: "",
                        searchResultsAll: [],
                    }, () =>{
                        this.fillShowWithOrderObj("L");
                    });
                }))
            .catch(error => console.error("Error", error));
    };

    
    componentDidMount() {
        this.props.dispatch(onCallActions.getSiteUsersStatus());
        
        if(this.state.searchTypeL == true && this.state.activeGoogleSearch){
            this.modifyInputBlur();
        }
        this.setTotal_active_filters();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ((nextProps.siteUserStatus !== undefined)) {
            
            if(this.state.firstCallStatus == 1){
                this.setState({
                    siteUserStatus: nextProps.siteUserStatus,
                    firstCallStatus: 2,
                    firstCallPosition: 2,
                    siteUserPosition: nextProps.siteUserPosition,
                    docChecked: true,
                    doctorStatusId: (JSON.parse(localStorage.getItem("doctorStatusId")) ? JSON.parse(localStorage.getItem("doctorStatusId")) : nextProps.siteUserStatus[0].id),
                    selecteDocPosition: (JSON.parse(localStorage.getItem("selecteDocPosition")) ? JSON.parse(localStorage.getItem("selecteDocPosition")) : [...new Set(nextProps.siteUserPosition.map((item) => item.id))]),
                    doctorPosition: (JSON.parse(localStorage.getItem("doctorPosition")) ? JSON.parse(localStorage.getItem("doctorPosition")) : [...new Set(nextProps.siteUserPosition.map((item) => item.id))]),
                    firstCallStudent: 2,
                    siteUserAcademicYears: nextProps.siteUserAcademicYears,
                    stuChecked: (JSON.parse(localStorage.getItem("stuChecked")) ? JSON.parse(localStorage.getItem("stuChecked")) : false),
                    studentStatusId: (JSON.parse(localStorage.getItem("studentStatusId")) ? JSON.parse(localStorage.getItem("studentStatusId")) : ""),
                    selecteStuPosition: (JSON.parse(localStorage.getItem("selecteStuPosition")) ? JSON.parse(localStorage.getItem("selecteStuPosition")) : []),
                    studentPosition: (JSON.parse(localStorage.getItem("studentPosition")) ? JSON.parse(localStorage.getItem("studentPosition")) : [...new Set(nextProps.siteUserAcademicYears.map((item) => item.id))]),
                    
                }, () =>
                    this.getSiteUser()
                );
            }

            if(this.state.searchTypeL == true && this.state.activeGoogleSearch){
                this.modifyInputBlur();
            }
        }
        

        
        if (nextProps.siteUsers !== undefined) {
            let reportList = [];
            for (var cc = 0; cc <= nextProps.siteUsers.total_siteusers; cc++) {
                if(cc==0){
                    reportList.push(cc);
                    cc= cc+100;
                }else if(cc == nextProps.siteUsers.total_siteusers){
                    reportList.push(cc);
                    cc= cc+100;
                }else{
                    reportList.push(cc);
                    cc= cc+100;
                    if(cc > nextProps.siteUsers.total_siteusers){
                        cc = nextProps.siteUsers.total_siteusers;
                    }
                }
                cc--;
            }

            let total_user = nextProps.siteUsers.total_siteusers;
            let free_msg_per = 0;
            if(total_user > 100){
                let single_per = (total_user/100);
                free_msg_per = (100/single_per);
            }else if(total_user > 1 && total_user < 101 ){
                free_msg_per = 100;
            }

            this.setState({
                //firstCallStatus: 5,
                siteUsers: nextProps.siteUsers,
                total_siteusers: nextProps.siteUsers.total_siteusers,
                member_count:nextProps.siteUsers.total_siteusers,
                isLoading: false,
                isLoadingCount: false,
                bookmarkType: "",
                inputMarkers: reportList,
                free_msg_per: free_msg_per,
            }, () =>
                this.updatePriceFromMemberCount()
            );
        }

        if (nextProps.searchResults !== undefined) {
            let item = nextProps.searchResults;
            let reportList = [];
            for (var c = 0; c < item.length; c++) {
                reportList.push({ id: item[c].id, name: item[c].name, parent_name: item[c].parent_name });
            }
            this.setState({
                searchResults: reportList
            });
        }
        
        if (nextProps.searchResultsAll !== undefined) {
            let item = nextProps.searchResultsAll;
            let reportList = [];

            let show_l_result = false;
            let show_s_result = false;
            let show_d_result = false;
            let show_i_result = false;


            for (var cc = 0; cc < item.length; cc++) {
                if(item[cc].item_type === "L"){ show_l_result = true; }
                if(item[cc].item_type === "S"){ show_s_result = true; }
                if(item[cc].item_type === "D"){ show_d_result = true; }
                if(item[cc].item_type === "I"){ show_i_result = true; }

                if(item[cc].item_type === "L"){
                    reportList.push({ id: item[cc].id, name: item[cc].name, parent_name: item[cc].parent_name, item_type: item[cc].item_type, item_name: item[cc].item_name, lat: item[cc].lat, lng: item[cc].lng });
                }else{
                    reportList.push({ id: item[cc].id, name: item[cc].name, parent_name: item[cc].parent_name, item_type: item[cc].item_type, item_name: item[cc].item_name });
                }
            }
            this.setState({
                searchResultsAll: reportList,
                show_l_result: show_l_result,
                show_s_result: show_s_result,
                show_d_result: show_d_result,
                show_i_result: show_i_result,
            });


        }
    }

    andOrMethod1(status) {
        if (status === true) {
            this.setState({
                andOrFilter1: "OR",
                andOrFilter1_status: true,
            }, () => {
                this.getSiteUser();
            });
        } else {
            this.setState({
                andOrFilter1: "AND",
                andOrFilter1_status: false,
            }, () => {
                this.getSiteUser();
            });
        }
    }
    andOrMethod2(status) {
        if (status === true) {
            this.setState({
                andOrFilter2: "OR",
                andOrFilter2_status: true,
            }, () => {
                this.getSiteUser();
            });
        } else {
            this.setState({
                andOrFilter2: "AND",
                andOrFilter2_status: false,
            }, () => {
                this.getSiteUser();
            });
        }
    }
    andOrMethod3(status) {
        if (status === true) {
            this.setState({
                andOrFilter3: "OR",
                andOrFilter3_status: true,
            }, () => {
                this.getSiteUser();
            });
        } else {
            this.setState({
                andOrFilter3: "AND",
                andOrFilter3_status: false,
            }, () => {
                this.getSiteUser();
            });
        }
    }

    specialitiesMethod(status) {
        if(this.state.specialitChecked === true){
            if (status === true) {
                this.setState({
                    specialities_method_status: true,
                    specialities_method: "AND",
                }, () => {
                    this.getSiteUser();
                });
            } else {
                this.setState({
                    specialities_method_status: false,
                    specialities_method: "OR",
                }, () => {
                    this.getSiteUser();
                });
            }
        }
        
    }

    locationRediusMethod(event) {
        if(this.state.locationChecked === true && this.state.show_redius_input === true){
            this.setState({
                location_redius: event.target.value,
            });
        }
        
    }

    locationRediusMethodCall(event) {
        if(this.state.locationChecked === true && this.state.show_redius_input === true){
            this.setActiveLocationRedius();
        }
    }

    locationRediusMethodNew(event) {
        if(this.state.locationChecked === true && this.state.show_redius_input === true){
            this.setState({
                location_redius: event,
            });
        }
        
    }

    locationRediusMethodCallNew(event) {
        if(this.state.locationChecked === true && this.state.show_redius_input === true){
            this.setActiveLocationRedius();
        }
    }

    memberCountMethod(event) {

        this.setMemberCountValue(event);

        
    }

    setMemberCountValue(event) {

        let total_user = event;
        let free_msg_per = 0;
        if(total_user > 100){
            let single_per = (total_user/100);
            free_msg_per = (100/single_per);
        }else if(total_user >= 0 && total_user < 101 ){
            free_msg_per = 100;
        }
        this.setState({
            free_msg_per:free_msg_per,
            member_count: event,
            member_count_final:event,
        });
    }

    memberCountMethodCall(event) {
        this.updatePriceFromMemberCount();
    }

    updatepriceMethod(event) {
        let val = parseInt(event.target.value, 10);
        let menber_count = 0;
        if (isNaN(val)) {
          val = "";
        } else {
            val = val >= 0 ? val : 0;
        }

        this.setState({
            msg_price: val,
        }, () => {
            this.updateMemberCountFromPrice();
        });
    }

    updatePriceFromMemberCount(){
        let member_count = this.state.member_count;
        let price_count = 0;
        if(parseInt(member_count) <= 100){
            this.setState({
                msg_price: 0,
                member_count_final: member_count,
            });
        }else{
            price_count = member_count / 100 ;
            if((price_count%1) !== 0){
                price_count = parseInt(price_count)+1;
            }
            price_count = price_count - 1;
            this.setState({
                msg_price: price_count,
                member_count_final: member_count,
            });
        }
    }

    updateMemberCountFromPrice(){
        let msg_price = this.state.msg_price;
        let total_siteusers = this.state.total_siteusers;
        let new_count = 0;
        if(msg_price === ""){
            this.setMemberCountValue(0);
        }else if(msg_price === 0){
            if(parseInt(total_siteusers) <= 100){
                this.setMemberCountValue(total_siteusers);
            }else{
                this.setMemberCountValue(100);
            }
        }else{
            new_count = (msg_price*100)+100;
            if(new_count > total_siteusers){
                this.setState({
                    member_count: total_siteusers,
                }, () => {
                    this.setMemberCountValue(total_siteusers);
                    this.updatePriceFromMemberCount();
                });
            }else{
                this.setState({
                    member_count: new_count,
                }, () => {
                    this.setMemberCountValue(new_count);
                    this.updatePriceFromMemberCount();
                });
            }
        }
    }

    locationRediusTypeMethod(event) {
        if(this.state.locationChecked === true && this.state.show_redius_input === true){
            let location_redius_type = event.target.value;
            if(location_redius_type === "KM"){
                this.setState({
                    location_redius_type: "MI",
                }, () => {
                    this.setActiveLocationRedius();
                });
            }else{
                this.setState({
                    location_redius_type: "KM",
                }, () => {
                    this.setActiveLocationRedius();
                });
            }
        }
    }

    diseasesMethod(status) {
        if(this.state.diseaseChecked === true){
            if (status === true) {
                this.setState({
                    diseases_method_status: true,
                    diseases_method: "AND",
                }, () => {
                    this.getSiteUser();
                });
            } else {
                this.setState({
                    diseases_method_status: false,
                    diseases_method: "OR",
                }, () => {
                    this.getSiteUser();
                });
            }
        }
        
    }

    interventionsMethod(status) {
        if(this.state.interventionChecked === true){
            if (status === true) {
                this.setState({
                    interventions_method_status: true,
                    interventions_method: "AND",
                }, () => {
                    this.getSiteUser();
                });
            } else {
                this.setState({
                    interventions_method_status: false,
                    interventions_method: "OR",
                }, () => {
                    this.getSiteUser();
                });
            }
        }
        
    }

    locationsMethod(status) {
        if(this.state.locationChecked === true){
            if (status === true) {
                this.setState({
                    locations_method_status: true,
                    locations_method: "AND",
                }, () => {
                    this.getSiteUser();
                });
            } else {
                this.setState({
                    locations_method_status: false,
                    locations_method: "OR",
                }, () => {
                    this.getSiteUser();
                });
            }
        }
        
    }

    

    
    checkandOrFilter(value){
        let filter1 = this.state.locationLatLng.length ? 1 : 0;
        
        let filter2 = this.state.specialitiesIds.length ? 1 : 0;
        let filter3 = this.state.diseasesIds.length ? 1 : 0;
        let filter4 = this.state.interventionIds.length ? 1 : 0;

        let active1 = this.state.locationChecked == false ? 0 : 1;
        let active2 = this.state.specialitChecked == false ? 0 : 1;
        let active3 = this.state.diseaseChecked == false ? 0 : 1;
        let active4 = this.state.interventionChecked == false ? 0 : 1;

        
        let totalActive = active1 + active2 + active3 + active4;
        let totalFilter = filter1 + filter2 + filter3 + filter4;
        if(totalFilter >= value && totalActive >= value && this.state.advanceFilter == true){
            return true;
        }
        else{
            return false;
        }
    }
    ifTwoFilter(){
        return this.checkandOrFilter(2);
    }

    ifThreeFilter(){
        return this.checkandOrFilter(3);
    }

    ifFourFilter(){
        return this.checkandOrFilter(4);
    }


    formatResult = (item) => {
        let searchString = this.state.searchString;
        const parts = item.name.split(new RegExp(`(${searchString})`, "gi"));

        return (

            <div key={item.id} className="line_upper">
                <div className="line_upper"></div>
                    <span style={{ display: "inline-block", textAlign: "left" }}>{parts.map(part => part.toString().toLowerCase() === searchString.toString().toLowerCase() ? <b>{part}</b> : part)}</span>
                    <span style={{ display: "inline-block", textAlign: "right", color: "gray", paddingLeft: "10px" }}>{item.parent_name}</span>
               
            </div>
        );
    };

    fillOrderNumberHtml(setFor){
        let orderNumberHtml = [];
        let total_active_filters = this.state.total_active_filters;
        var i = 0;
        for (i =1; i<total_active_filters+1; i++) {
            let curVal = i;
          orderNumberHtml.push(
            <li key={curVal} id ={curVal} className="dropdown-item" onClick={()=>this.changeOrderNumber(setFor,curVal)} >{curVal}</li>
          );
        }

        return orderNumberHtml;
    }

    formatResultAll = (item) => {
        let searchString = this.state.searchString;
        const parts = item.name.split(new RegExp(`(${searchString})`, "gi"));

        return (
            
            <span key={item.id}><span style={{ display: "inline-block", textAlign: "left" }}>{parts.map(part => part.toString().toLowerCase() === searchString.toString().toLowerCase() ? <b>{part}</b> : part)}</span><span style={{ display: "inline-block", textAlign: "right", color: "gray", paddingLeft: "10px" }}>{item.parent_name}</span><span style={{ display: "inline-block", textAlign: "right", color: "#cacaca", paddingLeft: "10px" }}>{item.item_name}</span></span>
            
        );
    };

    render() {
        const { siteUserStatus, siteUserPosition, siteUserAcademicYears, activeGoogleSearch, siteUsers } = this.state;
        const markers = [0,100,200,300,400,500];
        return (
            <>
            { this.state.isLoading || this.state.isLoadingOrder && 
                <div className="loader">
                    <Spinner animation="border" variant="light" />
                </div>
                
            }
            <style>{".cost_slider [data-reach-slider-range]{background: linear-gradient(90deg, rgba(170,170,170,1)"+this.state.free_msg_per+"%, rgba(0,153,255,0) "+this.state.free_msg_per+"%);}"}</style>
                <section>
                    <div className="row m-0">
                        <div className="col-lg-2 col-md-2 col-12 p-0"> </div>
                        <div className="col-lg-8 col-md-8 col-12 p-0 px-lg-0 px-md-0 px-3 py-4">
                            <div className="p-lg-5 p-md-2 p-2">
                                <section>
                                    <div className="row m-0">
                                        <div className="col-12 p-0">
                                            <h1 className="mb-0 page_heading text-center">OnCall</h1>
                                            <p className="mb-0 mt-3 page_heading_info text-center">A brand new, targeted messaging service <br className="d-none d-md-block d-lg-block" /> for doctor-to-doctor contact.</p>
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <div className="row m-0 mt-5">
                                        <div className="col-12 p-0">

                                            <div className="d-flex flex-lg-row flex-md-row flex-column align-items-lg-center">
                                                {
                                                    this.state.siteUserStatus[0] &&
                                                    <div className="p-0 mt-lg-0 mt-md-0 mt-0 me-4 d-flex align-items-center high_index_one">
                                                        <input
                                                            checked={this.state.docChecked}
                                                            // onChange={this.doctorStatus.bind(this, this.state.siteUserStatus[0].id)} 
                                                            className={"form-check-input custom_check_Box m-0 me-3"+ (this.state.half_checklist_dcotor ? " half_checklist_dcotor " : " ")}
                                                            type="checkbox"
                                                            onChange={this.doctorStatus.bind(this, !this.state.docChecked)}
                                                        />
                                                        <Dropdown drop="down" autoClose="outside" >
                                                            <Dropdown.Toggle variant="light" id="dropdown-basic" size="md" className={" "  + (this.state.docChecked ? "text_bold " : "") } >
                                                                {this.state.siteUserStatus[0].name}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                {
                                                                    siteUserPosition && siteUserPosition.map((sItem, ids) =>
                                                                        <div key={ids+"_doc"} className="dropdown_list d-flex align-items-center ">
                                                                            <Dropdown.Item onClick={this.selectDoctorPosition.bind(this, sItem.id)} className={(this.state.selecteDocPosition.includes(sItem.id) ? "text_bold ":"bgEEE ")+ "text-start "} >{sItem.name} </Dropdown.Item>
                                                                            {
                                                                                this.state.selecteDocPosition.includes(sItem.id) &&
                                                                                <FontAwesomeIcon style={{ marginRight: "10px" }} onClick={this.removeDoctorPosition.bind(this, sItem.id)} size="sm" icon="fa-solid fa-check" />
                                                                            }
                                                                        </div>
                                                                    )}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                }
                                                {
                                                    this.state.siteUserStatus[1] &&
                                                    <div className="p-0 mt-lg-0 mt-md-0 mt-2 d-flex align-items-center high_index_two">
                                                        <input
                                                            checked={this.state.stuChecked}
                                                            // onClick={this.studentStatus.bind(this, this.state.siteUserStatus[1].id)} 
                                                            className={"form-check-input custom_check_Box m-0 me-3 "+ (this.state.half_checklist_student ? " half_checklist_student " : "")}
                                                            type="checkbox"
                                                            onChange={this.studentStatus.bind(this, !this.state.stuChecked)}
                                                        />
                                                        <Dropdown drop="down" autoClose="outside" >
                                                            <Dropdown.Toggle variant="light" id="dropdown-basic" size="md" className={" "  + (this.state.stuChecked ? "text_bold " : "") } >
                                                                {this.state.siteUserStatus[1].name}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                {
                                                                    siteUserAcademicYears && siteUserAcademicYears.map((sItem, ids) =>
                                                                        <div key={ids+"stu"} className="dropdown_list d-flex align-items-center ">
                                                                            <Dropdown.Item onClick={this.selectStudentPosition.bind(this, sItem.id)} className={(this.state.selecteStuPosition.includes(sItem.id) ? "text_bold ":"bgEEE ") + " text-start " }  >{sItem.label} </Dropdown.Item>
                                                                            {
                                                                                this.state.selecteStuPosition.includes(sItem.id) &&
                                                                                <FontAwesomeIcon style={{ marginRight: "10px" }} onClick={this.removeStudentPosition.bind(this, sItem.id)} size="sm" icon="fa-solid fa-check" />
                                                                            }
                                                                        </div>
                                                                    )}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <div className="row m-0 mt-3">
                                        <div className="col-lg-8 col-md-12 col-12 p-0 d-flex align-items-center justify-content-between search_index">
                                                    <div className="sc-eCYdqJ bdHMCv gSearch" key="location_result">
                                                        <div className="wrapper">
                                                            <div className="clearfix"></div>
                                                            <div className="row color ">
                                                                <PlacesAutocomplete
                                                                    ref={this.setPlacesRef}
                                                                    value={this.state.address}
                                                                    onChange={this.handleChange}
                                                                    onSelect={this.handleSelect}
                                                                    searchOptions={{
                                                                        componentRestrictions: { country: "UK" },
                                                                        fields: ["address_components",],
                                                                    }}
                                                                >
                                                                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                                                        <React.Fragment>
                                                                            <div className="sc-hKMtZM jMcrRR ">
                                                                                <svg className="sc-bczRLJ fASA-Dv search-icon" width="20" height="20" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></svg>
                                                                                <input
                                                                                    type="text"
                                                                                    key="location_input"
                                                                                    className={"form-control brd_rmv p-0"+(this.state.activeSection ? " searchResultInput":"")}
                                                                                    placeholder="Username"
                                                                                    aria-label="Username"
                                                                                    aria-describedby="basic-addon1"
                                                                                    
                                                                                    onKeyPress={event => {
                                                                                        if (event.key === "Enter") {
                                                                                            this.handleSelect();
                                                                                        }
                                                                                    }}
                                                                                    {...getInputProps({
                                                                                        placeholder: "Search Something...",
                                                                                        className: "form-control_ search_input_box brd_rmv focus_in focus_out ps-0"+(this.state.activeSection ? " searchResultInput":"")
                                                                                    })}
                                                                                />
                                                                            </div>
                                                                            {
                                                                                (this.state.searchTypeL == true && this.state.activeGoogleSearch) &&
                                                                                <React.Fragment>
                                                                                    {suggestions.length > 0 &&
                                                                                            <div className="sc-gsnTZi pHieT">
                                                                                                <div className="line"></div>
                                                                                                <ul>
                                                                                                    {suggestions.map(suggestion => {
                                                                                                        const className = suggestion.active
                                                                                                            ? "suggestion-item--active"
                                                                                                            : "suggestion-item";
                                                                                                        // inline style for demonstration purpose
                                                                                                        const style = suggestion.active
                                                                                                            ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                                                                                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                                                                                                        
                                                                                                        let googleString = this.state.address;
                                                                                                        const parts = suggestion.description.split(new RegExp(`(${googleString})`, "gi"));
                            
                                                                                                        return (
                                                                                                            <li className="" data-test="result" key={suggestion.placeId+"lc"}
                                                                                                                {...getSuggestionItemProps(suggestion, {
                                                                                                                    className,
                                                                                                                    style,
                                                                                                                })}
                                                                                                            >
                                                                                                                <svg className="sc-bczRLJ fASA-Dv search-icon" width="20" height="20" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></svg>
                                                                                                                <div className="ellipsis" title="Vascular">
                                                                                                                    <span >{parts.map(part => part.toLowerCase() === googleString.toLowerCase() ? <b>{part}</b> : part)}</span>
                                                                                                                </div>
                                                                                                            </li>
                                                                                                        );
                                                                                                    })}
                                                                                                </ul>
                                                                                            </div>
                                                                                    }

                                                                                </React.Fragment>
                                                                            }
                                                                            {(this.state.searchResultsAll.length > 0 && this.state.searchTypeS == true && this.state.show_s_result == true) &&
                                                                
                                                                                <div className="sc-gsnTZi pHieT" onTouchStart={this.setSuggestionFocusOn.bind(this)} onTouchEnd={this.setSuggestionFocusOn.bind(this)} onFocus={this.setSuggestionFocusOn.bind(this)} onBlur={this.setSuggestionFocusOff.bind(this)} onMouseEnter={this.setSuggestionFocusOn.bind(this)} onMouseLeave={this.setSuggestionFocusOff.bind(this)} >
                                                                                    <div className="line"></div>
                                                                                    <ul>
                                                                                        {
                                                                                            this.state.searchResultsAll.map(item=>{
                                                                                            let searchString = this.state.address;
                                                                                            const parts = item.name.split(new RegExp(`(${searchString})`, "gi"));
                                                                                                return (
                                                                                                    (item.item_type == "S") &&
                                                                                                    <li className="pointer" key={item.id} data-test="result" id="PlacesAutocomplete__suggestion-23535353532"
                                                                                                    onClick={this.handleOnSelecteAll.bind(this,item)}  
                                                                                                     >
                                                                                                        <svg className="sc-bczRLJ fASA-Dv search-icon" width="20" height="20" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></svg>
                                                                                                        <div className="ellipsis" title="Vascular" >
                                                                                                            <span style={{ display: "inline-block", textAlign: "left" }}>{parts.map(part => part.toString().toLowerCase() === searchString.toString().toLowerCase() ? <b>{part}</b> : part)}</span>
                                                                                                            <span style={{ display: "inline-block", textAlign: "right", color: "gray", paddingLeft: "10px" }}>{item.parent_name}</span>
                                                                                                            <span style={{ display: "inline-block", textAlign: "right", color: "#cacaca", paddingLeft: "10px" }}>{item.item_name}</span>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                );
                                                                                            })
                                                                                        }
                                                                                    </ul>
                                                                                </div>
                                                                            }

                                                                            {(this.state.searchResultsAll.length > 0 && this.state.searchTypeD == true && this.state.show_d_result == true) &&
                                                                                <div className="sc-gsnTZi pHieT" onTouchStart={this.setSuggestionFocusOn.bind(this)} onTouchEnd={this.setSuggestionFocusOn.bind(this)} onFocus={this.setSuggestionFocusOn.bind(this)} onBlur={this.setSuggestionFocusOff.bind(this)} onMouseEnter={this.setSuggestionFocusOn.bind(this)} onMouseLeave={this.setSuggestionFocusOff.bind(this)} >
                                                                                    <div className="line"></div>
                                                                                    <ul>
                                                                                        {
                                                                                            this.state.searchResultsAll.map(item=>{
                                                                                            let searchString = this.state.address;
                                                                                            const parts = item.name.split(new RegExp(`(${searchString})`, "gi"));
                                                                                                return (
                                                                                                    (item.item_type == "D") &&
                                                                                                    <li className="pointer" key={item.id} data-test="result" id="PlacesAutocomplete__suggestion-23535353532"
                                                                                                    onClick={this.handleOnSelecteAll.bind(this,item)}  
                                                                                                     >
                                                                                                        <svg className="sc-bczRLJ fASA-Dv search-icon" width="20" height="20" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></svg>
                                                                                                        <div className="ellipsis" title="Vascular" >
                                                                                                            <span style={{ display: "inline-block", textAlign: "left" }}>{parts.map(part => part.toString().toLowerCase() === searchString.toString().toLowerCase() ? <b>{part}</b> : part)}</span>
                                                                                                            <span style={{ display: "inline-block", textAlign: "right", color: "gray", paddingLeft: "10px" }}>{item.parent_name}</span>
                                                                                                            <span style={{ display: "inline-block", textAlign: "right", color: "#cacaca", paddingLeft: "10px" }}>{item.item_name}</span>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                );
                                                                                            })
                                                                                        }
                                                                                    </ul>
                                                                                    
                                                                                </div>
                                                                            }

                                                                            {(this.state.searchResultsAll.length > 0 && this.state.searchTypeI == true && this.state.show_i_result == true) &&
                                                                
                                                                                <div className="sc-gsnTZi pHieT" onTouchStart={this.setSuggestionFocusOn.bind(this)} onTouchEnd={this.setSuggestionFocusOn.bind(this)} onFocus={this.setSuggestionFocusOn.bind(this)} onBlur={this.setSuggestionFocusOff.bind(this)} onMouseEnter={this.setSuggestionFocusOn.bind(this)} onMouseLeave={this.setSuggestionFocusOff.bind(this)} >
                                                                                    <div className="line"></div>
                                                                                    <ul>
                                                                                        {
                                                                                            this.state.searchResultsAll.map(item=>{
                                                                                            let searchString = this.state.address;
                                                                                            const parts = item.name.split(new RegExp(`(${searchString})`, "gi"));
                                                                                                
                                                                                                return (
                                                                                                    (item.item_type == "I") &&
                                                                                                    <li className="pointer" key={item.id} data-test="result" id="PlacesAutocomplete__suggestion-23535353532"
                                                                                                    onClick={this.handleOnSelecteAll.bind(this,item)}  
                                                                                                     >
                                                                                                        
                                                                                                        <svg className="sc-bczRLJ fASA-Dv search-icon" width="20" height="20" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></svg>
                                                                                                        <div className="ellipsis" title="Vascular" >
                                                                                                            <span style={{ display: "inline-block", textAlign: "left" }}>{parts.map(part => part.toString().toLowerCase() === searchString.toString().toLowerCase() ? <b>{part}</b> : part)}</span>
                                                                                                            <span style={{ display: "inline-block", textAlign: "right", color: "gray", paddingLeft: "10px" }}>{item.parent_name}</span>
                                                                                                            <span style={{ display: "inline-block", textAlign: "right", color: "#cacaca", paddingLeft: "10px" }}>{item.item_name}</span>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                );
                                                                                            })
                                                                                        }
                                                                                    </ul>
                                                                                    
                                                                                </div>
                                                                            }

                                                                        </React.Fragment>
                                                                )}
                                                                </PlacesAutocomplete>
                                                            </div>
                                                        </div>
                                                    </div>
                                        </div>
                                        <div className="col-lg-4 col-md-12 col-12 p-0 mt-lg-0 mt-md-2 mt-2">
                                            <div className="buttons_group d-flex align-items-center justify-content-lg-end">
                                                <OverlayTrigger overlay={(props) => (<Tooltip {...props}> Location </Tooltip>)} placement="top" >
                                                    <button onClick={this.SearchHandler.bind(this, "L")} className={"ms-lg-3 me-lg-0 me-md-3 me-3 filter_buttons  d-flex align-items-center justify-content-center justify-content-center " + (this.state.searchTypeL == true ? "active_filter_buttons 1" : "")}>L</button>
                                                </OverlayTrigger>
                                                <OverlayTrigger overlay={(props) => (<Tooltip {...props}> Speciality </Tooltip>)} placement="top" >
                                                    <button onClick={this.SearchHandler.bind(this, "S")} className={"ms-lg-3 me-lg-0 me-md-3 me-3 filter_buttons  d-flex align-items-center justify-content-center justify-content-center " + (this.state.searchTypeS == true ? "active_filter_buttons 2" : "")} >S</button>
                                                </OverlayTrigger>
                                                <OverlayTrigger overlay={(props) => (<Tooltip {...props}> Disease </Tooltip>)} placement="top" >
                                                    <button onClick={this.SearchHandler.bind(this, "D")} className={"ms-lg-3 me-lg-0 me-md-3 me-3 filter_buttons  d-flex align-items-center justify-content-center justify-content-center " + (this.state.searchTypeD == true ? "active_filter_buttons 3" : "")} >D</button>
                                                </OverlayTrigger>
                                                <OverlayTrigger overlay={(props) => (<Tooltip {...props}> Intervention </Tooltip>)} placement="top" >
                                                    <button onClick={this.SearchHandler.bind(this, "I")} className={"ms-lg-3 me-lg-0 me-md-3 me-3 filter_buttons  d-flex align-items-center justify-content-center justify-content-center " + (this.state.searchTypeI == true ? "active_filter_buttons 4" : "")} >I</button>
                                                </OverlayTrigger>
                                            </div>

                                        </div>
                                    </div>

                                    <p className="mb-0 mt-1 text-lg-end text-start sort_indecators mt-lg-1 mt-md-2 mt-2">Refine by Location (L), Speciality (S), Disease (D) and Intervention (I)</p>

                                </section>

                                <section>
                                    <div className="row m-0 mt-5">
                                        <div className="col-lg-12 col-md-12 col-12 p-0 d-flex align-items-center justify-content-center">
                                            <p className="mb-0 me-2 adv_filter_text">Advanced Filtering </p>
                                            <div className="form-check form-switch m-0">
                                                <input
                                                    className="form-check-input switch"
                                                    type="checkbox"
                                                    role="switch"
                                                    id="flexSwitchCheckDefault"
                                                    checked={this.state.advanceFilter}
                                                    onChange={this.advanceFilter.bind(this, !this.state.advanceFilter)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section>
                                    <div className="row">
                                        {
                                          this.state.showWithOrderObj.map((todo,indexNew) => (
                                            <React.Fragment key={indexNew}>
                                            {(todo === "L") &&
                                                <React.Fragment>
                                                    {
                                                        this.state.location.length > 0 &&
                                                        <div className={"col-12 "} rj={indexNew}>
                                                        <div className={"row m-0 mt-4 mb-4" + (this.state.locationChecked ? "" : " section_opacity")} >
                                                            <div className="col-lg-12 col-md-12 col-12 p-lg-4 p-md-4 p-2 cards_parent">

                                                                <div className="row m-0">

                                                                    <div className="col-lg-3 col-md-12 col-12 p-1">

                                                                        <div className="d-flex align-items-center">
                                                                            <input checked={this.state.locationChecked}
                                                                                onChange={this.locationStatus.bind(this, !this.state.locationChecked)}
                                                                                className="form-check-input custom_check_Box m-0 me-3" type="checkbox" name="" id="" />
                                                                            <p className="mb-0 location_card_heading">Location</p>
                                                                        </div>

                                                                    </div>

                                                                    <div className="col-lg-7 col-md-12 col-12 p-0 mt-lg-0 mt-md-2 mt-2 d-flex flex-wrap">

                                                                        {
                                                                            this.state.location.length > 0 && this.state.location.map((record, indx) =>
                                                                                <div key={indx + "_location"}>
                                                                                    <button disabled={!this.state.locationChecked} className={"p-1 mx-1 mt-1 adv_flt_tabs_btn d-flex justify-content-between" + (this.checkLocationmatch(record.latLng) ? " active_adv_flt_tabs_btn" : "")}>
                                                                                        {(this.state.advanceFilter) &&
                                                                                            <React.Fragment>
                                                                                                {(this.state.locationChecked && this.checkLocationmatch(record.latLng)) ?

                                                                                                    <React.Fragment>
                                                                                                        <OverlayTrigger overlay={(props) => (<Tooltip {...props}> Adjust Range </Tooltip>)} placement="top" >
                                                                                                            <FontAwesomeIcon icon="fa-solid fa-registered" className={"ms-1 radius_icon radius_icon_color "+ ((record.latLng.lat === this.state.active_location.lat && record.latLng.lng === this.state.active_location.lng )? " active_radius_class":" ") } onClick={this.FilterLocationRedius.bind(this,record.latLng)} />
                                                                                                        </OverlayTrigger>
                                                                                                    </React.Fragment> 
                                                                                                
                                                                                                :
                                                                                                    <FontAwesomeIcon icon="fa-solid fa-registered" className={"ms-1 radius_icon radius_icon_color "+ ((record.latLng.lat === this.state.active_location.lat && record.latLng.lng === this.state.active_location.lng )? "active_radius_class":"") + (this.checkLocationmatch(record.latLng)? "" : " inactive_radius_class" ) } onClick={this.FilterLocationRedius.bind(this,record.latLng)} />
                                                                                                }
                                                                                            </React.Fragment> 
                                                                                        }  &nbsp;  
                                                                                        <span className="mx-2" onClick={this.AdvanceFilterLocation.bind(this,record.latLng)} key={record.id+ "locRec"} >{record.address}</span> 
                                                                                        &nbsp; 
                                                                                        <FontAwesomeIcon icon="fa-solid fa-xmark" className={"cross_icon"+ (this.state.locationChecked ? " " : " cursor_pointer")} onClick={this.DeleteFilterLocation.bind(this,record.latLng)} />  
                                                                                    </button>
                                                                                </div>
                                                                            )
                                                                        }

                                                                    </div>
                                                                    <div className={"col-lg-2 col-md-12 col-12 p-0 pe-1 mt-lg-0 mt-md-2 mt-2 d-none d-md-none d-lg-block d-xl-block align-self-center "+ (this.state.locationChecked ? "" : " section_opacity")}>
                                                                        {(this.state.advanceFilter && this.state.locationLatLng.length > 1) &&
                                                                            <React.Fragment>
                                                                                <div className="d-flex justify-content-end">
                                                                                    <p className={"mb-1 me-2  text-center and_or_text "+ (this.state.locations_method_status ? "" : "active_text") }>or</p>
                                                                                </div>

                                                                                <div className="form-check form-switch m-0 p-0 d-flex align-items-center justify-content-end">
                                                                                    <input disabled={!this.state.locationChecked} className="form-check-input rotated_switch switch m-0" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                                                                                        checked={this.state.locations_method_status}
                                                                                        
                                                                                        onChange={this.locationsMethod.bind(this, !this.state.locations_method_status)} />
                                                                                </div>
                                                                                <div className="d-flex justify-content-end">
                                                                                    <p className={"mb-0 mt-1 me-1 text-center and_or_text "+ (this.state.locations_method_status ? "active_text" : "") }>and</p>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {(this.state.advanceFilter) &&
                                                                    <div className="row m-0 mt-3">

                                                                        <div className="col-lg-3 col-md-12 col-12 p-0"> </div>

                                                                        <div className="col-lg-7 col-md-12 col-12 p-0 p-2 mt-lg-0 mt-md-2 mt-2">
                                                                            <div className="d-flex">

                                                                                <div className="d-flex">

                                                                                    <p className={"mb-0 m-0 me-3 mt-1 range_info_text active_range_info_text "+(this.state.show_redius_input === true ? "active_radius":"")}>RANGE</p>

                                                                                    <div className={"rang_box me-3 d-flex align-items-center "+(this.state.show_redius_input === true ? "rang_box_active":"")}>
                                                                                        
                                                                                        <input disabled={!this.state.locationChecked} type="range" className="hide d-none form-range" min="-1" max="50" step="1"
                                                                                            value={this.state.location_redius}
                                                                                            onChange={this.locationRediusMethod.bind(this)}
                                                                                            onMouseUp={this.locationRediusMethodCall.bind(this)}
                                                                                            onTouchEnd={this.locationRediusMethodCall.bind(this)}
                                                                                            id="customRange3_" />

                                                                                        

                                                                                        <div className="row m-0 hide d-none">
                                                                                            <div className="col-lg-12 col-md-12 col-12 p-0 d-flex align-items-center justify-content-between">
                                                                                                <div className="range_value_box d-flex align-items-center justify-content-start"> <p className={"mb-0 radius_rang_text "+(this.state.show_redius_input === true ? "active_radius":"")}>0</p> </div>
                                                                                                <div className="range_value_box d-flex align-items-center justify-content-center"> <p className={"mb-0 radius_rang_text "+(this.state.show_redius_input === true ? "active_radius":"")}>{this.state.location_redius < 0 ? "" : this.state.location_redius}</p> </div>
                                                                                                <div className="range_value_box d-flex align-items-center justify-content-end"> <p className={"mb-0 radius_rang_text "+(this.state.show_redius_input === true ? "active_radius":"")}>50</p> </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        
                                                                                        
                                                                                        <SliderInput 
                                                                                            onChange={this.locationRediusMethodNew.bind(this)}
                                                                                            onMouseUp={this.locationRediusMethodCallNew.bind(this)}
                                                                                            onTouchEnd={this.locationRediusMethodCallNew.bind(this)}
                                                                                            min={-1} max={50} step={1} value={this.state.location_redius}
                                                                                            
                                                                                            className="ewclass radius_width"
                                                                                            orientation="horizontal"
                                                                                        >   
                                                                                            <SliderTrack>
                                                                                                <SliderRange value={this.state.location_redius} >
                                                                                                    <span>{this.state.location_redius}</span>
                                                                                                </SliderRange>
                                                                                                <SliderHandle className={(this.state.show_redius_input === true ? "radius_box_active":"radius_box_inactive")}>
                                                                                                    <span className={"radius_rang_text "+(this.state.show_redius_input === true ? "active_radius":"")}>{ this.state.location_redius == -1  ? "" :(this.state.location_redius == 50 ? "" : (this.state.location_redius == 0 ? "" : this.state.location_redius))}</span>
                                                                                                </SliderHandle>
                                                                                                
                                                                                                <SliderMarker value={-1} key={-1} className={"radius_rang_text "+(this.state.show_redius_input === true ? "active_radius":"")}>
                                                                                                    <span className={"firstMarker"}>
                                                                                                      {0}
                                                                                                    </span>
                                                                                                </SliderMarker>
                                                                                                <SliderMarker value={50} key={50} className={"radius_rang_text "+(this.state.show_redius_input === true ? "active_radius":"")}>
                                                                                                    <span className={"lastMarker"}>
                                                                                                      {50}
                                                                                                    </span>
                                                                                                </SliderMarker>
                                                                                                
                                                                                            </SliderTrack>
                                                                                        </SliderInput>
                                                                                        
                                                                                    </div>
                                                                                    
                                                                                </div>

                                                                                <div className="">

                                                                                    {/*<p className="mb-0 range_info_text active_range_info_text">Select a location to adjust</p>*/}

                                                                                    <div className=" d-flex align-items-center">

                                                                                        <p className={"mb-0 me-2 radius_rang_text "+(this.state.show_redius_input === true ? "":" inactive_text")+(this.state.location_redius_type == "KM" ? " active_text_light" : " active_text_bold" )}>mi</p>

                                                                                        <div className="form-check form-switch m-0">
                                                                                            <input disabled={!this.state.locationChecked} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                                                                                                value={this.state.location_redius_type}
                                                                                                checked={(this.state.location_redius_type == "KM" ? true : false )}
                                                                                                onChange={this.locationRediusTypeMethod.bind(this)} />
                                                                                        </div>

                                                                                        <p className={"mb-0 radius_rang_text "+(this.state.show_redius_input === true ? "":" inactive_text")+(this.state.location_redius_type == "KM" ? " active_text_bold" : " active_text_light" )}>km</p>
                                                                                    </div>

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-2"></div>
                                                                    </div>
                                                                }

                                                                {
                                                                    this.state.advanceFilter &&
                                                                    <div className={"row m-0 mt-3 " + (this.state.locationChecked ? "" : " section_opacity")} >
                                                                        <div className="col-10 p-0 d-flex align-items-end justify-content-lg-end ">
                                                                            {(this.state.advanceFilter && this.state.locationLatLng.length > 1) &&
                                                                                <React.Fragment>
                                                                                    <div className="d-lg-none d-xl-none d-flex align-items-end justify-content-lg-end">
                                                                                        <p className={"mb-0 me-2 and_or_text "+ (this.state.locations_method_status ? "" : "active_text") }>or</p>

                                                                                        <div className="form-check form-switch m-0">
                                                                                            <input disabled={!this.state.locationChecked} className="form-check-input switch" type="checkbox" role="switch" 
                                                                                                checked={this.state.locations_method_status}
                                                                                                onChange={this.locationsMethod.bind(this, !this.state.locations_method_status)} />
                                                                                        </div>

                                                                                        <p className={"mb-0 and_or_text "+ (this.state.locations_method_status ? "active_text" : "") }>and</p>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            }
                                                                        </div>

                                                                        <div className="col-2 p-0 mt-lg-0 mt-md-4 mt-3 d-flex align-items-center justify-content-lg-end justify-content-md-end justify-content-end">
                                                                            <div className={"dropdown "+(this.state.total_active_filters>1 ? "": "hide d-none")}>
                                                                                <button disabled={!this.state.locationChecked} className="btn btn-secondary dropdown-toggle standalone_select" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">{this.state.location_filter_order}</button>
                                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                    {this.fillOrderNumberHtml("L")}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        </div>
                                                    }
                                                </React.Fragment>
                                            }
                                            {(todo === "S") &&
                                                <React.Fragment>
                                                    {
                                                        this.state.specialities.length > 0 &&
                                                        <div className={"col-12 "}  rj={indexNew}>
                                                        <div className={"row m-0  mb-4 mt-4" + (this.state.specialitChecked ? "" : " section_opacity")} >
                                                            <div className="col-lg-12 col-md-12 col-12 p-lg-4 p-md-4 p-2 cards_parent">

                                                                <div className="row m-0">

                                                                    <div className="col-lg-3 col-md-12 col-12 p-1">

                                                                        <div className="d-flex align-items-center">

                                                                            <input checked={this.state.specialitChecked}
                                                                                onChange={this.specialitStatus.bind(this, !this.state.specialitChecked)}
                                                                                className="form-check-input custom_check_Box m-0 me-3" type="checkbox" name="" value="" id="" />
                                                                            <p className="mb-0 location_card_heading">Speciality</p>
                                                                        </div>

                                                                    </div>

                                                                    <div className="col-lg-7 col-md-12 col-12 p-0 mt-lg-0 mt-md-2 mt-2 d-flex flex-wrap">
                                                                        {
                                                                            this.state.specialities.length > 0 && this.state.specialities.map((record, indx) =>
                                                                                <div key={indx+"_specialities"}>
                                                                                    <button disabled={!this.state.specialitChecked} className={"p-1 mx-1 mt-1 adv_flt_tabs_btn d-flex justify-content-between" + (this.state.specialitiesIds.includes(record.id) ? " active_adv_flt_tabs_btn" : "")}><span className="me-2" onClick={this.AdvanceFilterSpeciality.bind(this, record.id)} key={indx+"speciality"}>{record.name}</span> &nbsp; <FontAwesomeIcon icon="fa-solid fa-xmark" className={"cross_icon"+ (this.state.specialitChecked ? " " : " cursor_pointer")}  onClick={this.DeleteFilterSpeciality.bind(this, record.id)} />  </button>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div className={"col-lg-2 col-md-12 col-12 p-0 pe-1 mt-lg-0 mt-md-2 mt-2 d-none d-md-none d-lg-block d-xl-block align-self-center "+ (this.state.specialitChecked ? "" : " section_opacity")}>
                                                                        {(this.state.advanceFilter && this.state.specialitiesIds.length > 1) &&
                                                                            <React.Fragment>
                                                                                <div className="d-flex justify-content-end">
                                                                                    <p className={"mb-1 me-2  text-center and_or_text "+ (this.state.specialities_method_status ? "" : "active_text") }>or</p>
                                                                                </div>

                                                                                <div className="form-check form-switch m-0 p-0 d-flex align-items-center justify-content-end">
                                                                                    <input disabled={!this.state.specialitChecked} className="form-check-input rotated_switch switch m-0" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                                                                                        checked={this.state.specialities_method_status}
                                                                                        onChange={this.specialitiesMethod.bind(this, !this.state.specialities_method_status)} />
                                                                                </div>
                                                                                <div className="d-flex justify-content-end">
                                                                                    <p className={"mb-0 mt-1 me-1 text-center and_or_text "+ (this.state.specialities_method_status ? "active_text" : "") }>and</p>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        }
                                                                    </div>
                                                                </div>

                                                                {
                                                                    this.state.advanceFilter &&
                                                                    <div className={"row m-0 mt-3 " + (this.state.specialitChecked ? "" : " section_opacity")} >
                                                                        <div className="col-10 p-0 d-flex align-items-end justify-content-lg-end">
                                                                            {(this.state.advanceFilter && this.state.specialitiesIds.length > 1) &&
                                                                                <React.Fragment>
                                                                                    <div className="d-lg-none d-xl-none d-flex align-items-end justify-content-lg-end">
                                                                                        <p className={"mb-0 me-2 and_or_text "+ (this.state.specialities_method_status ? "" : "active_text")}>or</p>

                                                                                        <div className="form-check form-switch m-0">
                                                                                            <input disabled={!this.state.specialitChecked} className="form-check-input switch" checked={this.state.specialities_method_status} type="checkbox" role="switch" id="flexSwitchCheckDefault"

                                                                                                onChange={this.specialitiesMethod.bind(this, !this.state.specialities_method_status)} />
                                                                                        </div>

                                                                                        <p className={"mb-0 and_or_text "+ (this.state.specialities_method_status ? "active_text" : "")}>and</p>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            }
                                                                        </div>

                                                                        <div className="col-2 p-0 mt-lg-0 mt-md-4 mt-3 d-flex align-items-center justify-content-lg-end justify-content-md-end justify-content-end">

                                                                            
                                                                            <div className={"dropdown "+(this.state.total_active_filters>1 ? "": "hide d-none")}>
                                                                                <button disabled={!this.state.specialitChecked} className="btn btn-secondary dropdown-toggle standalone_select" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">{this.state.speciality_filter_order}</button>
                                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                    {this.fillOrderNumberHtml("S")}
                                                                                </ul>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        </div>
                                                    }
                                                </React.Fragment>
                                            }

                                            {(todo === "D") &&
                                                <React.Fragment>
                                                    {
                                                        this.state.diseases.length > 0 &&
                                                        <div className={"col-12 "}  rj={indexNew}>
                                                        <div className={"row m-0 mb-4 mt-4" + (this.state.diseaseChecked ? "" : " section_opacity")} >
                                                            <div className="col-lg-12 col-md-12 col-12 p-lg-4 p-md-4 p-2 cards_parent">

                                                                <div className="row m-0">

                                                                    <div className="col-lg-3 col-md-12 col-12 p-1">

                                                                        <div className="d-flex align-items-center">
                                                                            <input checked={this.state.diseaseChecked}
                                                                                onChange={this.diseasesStatus.bind(this, !this.state.diseaseChecked)}
                                                                                className="form-check-input custom_check_Box m-0 me-3" type="checkbox" name="" value="" id="" />
                                                                            <p className="mb-0 location_card_heading">Disease</p>
                                                                        </div>

                                                                    </div>

                                                                    <div className="col-lg-7 col-md-12 col-12 p-0 mt-lg-0 mt-md-2 mt-2 d-flex flex-wrap">
                                                                        {
                                                                            this.state.diseases.length > 0 && this.state.diseases.map((record, indx) =>
                                                                                <div key={indx+"_diseases"}>
                                                                                    <button disabled={!this.state.diseaseChecked} className={"p-1 mx-1 mt-1 adv_flt_tabs_btn d-flex justify-content-between" + (this.state.diseasesIds.includes(record.id) ? " active_adv_flt_tabs_btn" : "")}><span className="me-2" onClick={this.AdvanceFilterDisease.bind(this, record.id)} key={indx+"disease"}>{record.name}</span> &nbsp; <FontAwesomeIcon icon="fa-solid fa-xmark" className={"cross_icon"+ (this.state.diseaseChecked ? " " : " cursor_pointer")}  onClick={this.DeleteFilterDisease.bind(this, record.id)} />  </button>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div className={"col-lg-2 col-md-12 col-12 p-0 pe-1 mt-lg-0 mt-md-2 mt-2 d-none d-md-none d-lg-block d-xl-block align-self-center "+ (this.state.diseaseChecked ? "" : " section_opacity")}>
                                                                        {(this.state.advanceFilter && this.state.diseasesIds.length > 1) &&
                                                                            <React.Fragment>
                                                                                <div className="d-flex justify-content-end">
                                                                                    <p className={"mb-1 me-2  text-center and_or_text "+ (this.state.diseases_method_status ? "" : "active_text") }>or</p>
                                                                                </div>

                                                                                <div className="form-check form-switch m-0 p-0 d-flex align-items-center justify-content-end">
                                                                                    <input disabled={!this.state.diseaseChecked} className="form-check-input rotated_switch switch m-0" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                                                                                        checked={this.state.diseases_method_status}
                                                                                        onChange={this.diseasesMethod.bind(this, !this.state.diseases_method_status)} />
                                                                                </div>
                                                                                <div className="d-flex justify-content-end">
                                                                                    <p className={"mb-0 mt-1 me-1 text-center and_or_text "+ (this.state.diseases_method_status ? "active_text" : "") }>and</p>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        }
                                                                    </div>
                                                                </div>

                                                                
                                                                {(this.state.advanceFilter) &&
                                                                    <React.Fragment>
                                                                        
                                                                        <div className={"row m-0 mt-3 " + (this.state.diseaseChecked ? "" : " section_opacity")} >
                                                                            <div className="col-10 p-0 d-flex align-items-end justify-content-lg-end">
                                                                                {(this.state.advanceFilter && this.state.diseasesIds.length > 1) &&
                                                                                    <React.Fragment>
                                                                                        <div className="d-lg-none d-xl-none d-flex align-items-end justify-content-lg-end">
                                                                                            <p className={"mb-0 me-2 and_or_text "+ (this.state.diseases_method_status ? "" : "active_text")}>or</p>

                                                                                            <div className="form-check form-switch m-0">
                                                                                                <input disabled={!this.state.diseaseChecked} className="form-check-input switch" 
                                                                                                    checked={this.state.diseases_method_status}
                                                                                                    type="checkbox" role="switch" id="flexSwitchCheckDefault"
                                                                                                    onChange={this.diseasesMethod.bind(this, !this.state.diseases_method_status)} />

                                                                                            </div>

                                                                                            <p className={"mb-0 and_or_text "+ (this.state.diseases_method_status ? "active_text" : "")}>and</p>
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                }
                                                                            </div>
                                                                            <div className="col-2 p-0 mt-lg-0 mt-md-4 mt-3 d-flex align-items-center justify-content-lg-end justify-content-md-end justify-content-end">

                                                                                
                                                                                <div className={"dropdown "+(this.state.total_active_filters>1 ? "": "hide d-none")}>
                                                                                    <button disabled={!this.state.diseaseChecked} className="btn btn-secondary dropdown-toggle standalone_select" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">{this.state.disease_filter_order}</button>
                                                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                        {this.fillOrderNumberHtml("D")}
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>   
                                                                }
                                                            </div>
                                                        </div>
                                                        </div>
                                                    }
                                                </React.Fragment>
                                            }

                                            {(todo === "I") &&
                                                <React.Fragment>
                                                    {
                                                        this.state.intervention.length > 0 &&
                                                        <div className={"col-12 "}  rj={indexNew}>
                                                        <div className={"row m-0 mb-4 mt-4 " + (this.state.interventionChecked ? "" : " section_opacity")} >
                                                            <div className="col-lg-12 col-md-12 col-12 p-lg-4 p-md-4 p-2 cards_parent">

                                                                <div className="row m-0">

                                                                    <div className="col-lg-3 col-md-12 col-12 p-1">

                                                                        <div className="d-flex align-items-center">

                                                                            <input checked={this.state.interventionChecked}
                                                                                onChange={this.interventionStatus.bind(this, !this.state.interventionChecked)}
                                                                                className="form-check-input custom_check_Box m-0 me-3" type="checkbox" name="" value="" id="" />
                                                                            <p className="mb-0 location_card_heading">Intervention</p>
                                                                        </div>

                                                                    </div>

                                                                    <div className="col-lg-7 col-md-12 col-12 p-0 mt-lg-0 mt-md-2 mt-2 d-flex flex-wrap">
                                                                        {
                                                                            this.state.intervention.length > 0 && this.state.intervention.map((record, indx) =>
                                                                                <div key={indx+"_intervention"}>
                                                                                    <button disabled={!this.state.interventionChecked} className={"p-1 mx-1 mt-1 adv_flt_tabs_btn d-flex justify-content-between" + (this.state.interventionIds.includes(record.id) ? " active_adv_flt_tabs_btn" : "")}><span className="me-2" onClick={this.AdvanceFilterIntervention.bind(this, record.id)} key={indx+"_phase"}>{record.name}</span> &nbsp; <FontAwesomeIcon icon="fa-solid fa-xmark" className={"cross_icon"+ (this.state.interventionChecked ? " " : " cursor_pointer")}  onClick={this.DeleteFilterIntervention.bind(this, record.id)} /> </button>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div className={"col-lg-2 col-md-12 col-12 p-0 pe-1 mt-lg-0 mt-md-2 mt-2 d-none d-md-none d-lg-block d-xl-block align-self-center "+ (this.state.interventionChecked ? "" : " section_opacity")}>
                                                                        {(this.state.advanceFilter && this.state.interventionIds.length > 1) &&
                                                                            <React.Fragment>
                                                                                <div className="d-flex justify-content-end">
                                                                                    <p className={"mb-1 me-2  text-center and_or_text "+ (this.state.interventions_method_status ? "" : "active_text") }>or</p>
                                                                                </div>

                                                                                <div className="form-check form-switch m-0 p-0 d-flex align-items-center justify-content-end">
                                                                                    <input disabled={!this.state.interventionChecked} className="form-check-input rotated_switch switch m-0" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                                                                                        checked={this.state.interventions_method_status}
                                                                                        onChange={this.interventionsMethod.bind(this, !this.state.interventions_method_status)} />
                                                                                </div>
                                                                                <div className="d-flex justify-content-end">
                                                                                    <p className={"mb-0 mt-1 me-1 text-center and_or_text "+ (this.state.interventions_method_status ? "active_text" : "") }>and</p>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        }
                                                                    </div>
                                                                    
                                                                </div>
                                                                {
                                                                    this.state.advanceFilter &&
                                                                    <div className={"row m-0 mt-3 " + (this.state.interventionChecked ? "" : " section_opacity")} >
                                                                        <div className="col-10 p-0 d-flex align-items-end justify-content-lg-end">
                                                                            {(this.state.advanceFilter && this.state.interventionIds.length > 1) &&
                                                                                <React.Fragment>
                                                                                    <div className="d-lg-none d-xl-none d-flex align-items-end justify-content-lg-end">
                                                                                        <p className={"mb-0 me-2 and_or_text "+ (this.state.interventions_method_status ? "" : "active_text")}>or</p>

                                                                                        <div className="form-check form-switch m-0">
                                                                                            <input disabled={!this.state.interventionChecked} className="form-check-input switch" type="checkbox" role="switch" 
                                                                                                checked={this.state.interventions_method_status}
                                                                                                onChange={this.interventionsMethod.bind(this, !this.state.interventions_method_status)} id="flexSwitchCheckDefault" />
                                                                                        </div>

                                                                                        <p className={"mb-0 and_or_text "+ (this.state.interventions_method_status ? "active_text" : "")}>and</p>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            }
                                                                        </div>
                                                                        <div className="col-2 p-0 mt-lg-0 mt-md-4 mt-3 d-flex align-items-center justify-content-lg-end justify-content-md-end justify-content-end">

                                                                            
                                                                            <div className={"dropdown "+(this.state.total_active_filters>1 ? "": "hide d-none")}>
                                                                                <button disabled={!this.state.interventionChecked} className="btn btn-secondary dropdown-toggle standalone_select" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">{this.state.intervention_filter_order}</button>
                                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                    {this.fillOrderNumberHtml("I")}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        </div>
                                                    }
                                                </React.Fragment>
                                            }

                                            {(indexNew == this.state.temp_var_for_andor1) &&
                                                <React.Fragment>
                                                    {
                                                        this.ifTwoFilter() ?
                                                            <React.Fragment>
                                                                <div className={"col-12 order--2"+(this.state.temp_var_for_link1? " reduse_margin" : "")}  rj={indexNew}>
                                                                <div className={"row m-0"} >
                                                                    <div className="col-6 p-0_ d-flex align-items-center justify-content-start andor_icon">

                                                                        <p className={"mb-0 me-2 and_or_text "+ (this.state.andOrFilter1_status ? "" : "active_text")}>{this.state.temp_var_for_link1  == 1 ? "and" : "AND"}</p>

                                                                        <div className="form-check form-switch m-0">
                                                                            <input className="form-check-input switch" checked={this.state.andOrFilter1_status} type="checkbox" role="switch" id="flexSwitchCheckDefault"

                                                                                onChange={this.andOrMethod1.bind(this, !this.state.andOrFilter1_status)}  />
                                                                        </div>

                                                                        <p className={"mb-0 and_or_text "+ (this.state.andOrFilter1_status ? "active_text" : "")}>{this.state.temp_var_for_link1  == 1 ? "or" : "OR"}</p>
                                                                    </div>
                                                                    <div className="col-6 p-0 d-flex align-items-center justify-content-end">
                                                                        <div className="link_icon">
                                                                            { this.state.show_link1  === 1 &&
                                                                                <FontAwesomeIcon icon="fa-solid fa-link" className={"cursor_pointer"+ (this.state.temp_var_for_link1 === 1 ? " active_radius_class" : "")} onClick={()=>this.removeLinking(1)} />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                            </React.Fragment>
                                                        :
                                                        " "

                                                    }
                                                </React.Fragment>
                                            }

                                            {(indexNew == this.state.temp_var_for_andor2) &&
                                                <React.Fragment>
                                                    {
                                                        this.ifThreeFilter() ?
                                                            <React.Fragment>
                                                            <div className={"col-12 order--4"+(this.state.temp_var_for_link2? " reduse_margin" : "")}  rj={indexNew}>
                                                                <div className={"row m-0 "} >
                                                                    <div className="col-6 p-0_ d-flex align-items-center justify-content-start andor_icon">

                                                                        <p className={"mb-0 me-2 and_or_text "+ (this.state.andOrFilter2_status ? "" : "active_text")}>{this.state.temp_var_for_link2  == 1 ? "and" : "AND"}</p>

                                                                        <div className="form-check form-switch m-0">
                                                                            <input className="form-check-input switch" checked={this.state.andOrFilter2_status} type="checkbox" role="switch" id="andOrFilter2_status"

                                                                                onChange={this.andOrMethod2.bind(this, !this.state.andOrFilter2_status)}  />
                                                                        </div>

                                                                        <p className={"mb-0 and_or_text "+ (this.state.andOrFilter2_status ? "active_text" : "")}>{this.state.temp_var_for_link2  == 1 ? "or" : "OR"}</p>

                                                                    </div>
                                                                    <div className="col-6 p-0 d-flex align-items-center justify-content-end ">
                                                                        <div className="link_icon">
                                                                            { this.state.show_link2 == 1 &&
                                                                                <FontAwesomeIcon icon="fa-solid fa-link" className={"cursor_pointer"+ (this.state.temp_var_for_link2 === 1 ? " active_radius_class" : "")} onClick={()=>this.removeLinking(2)} />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                            </React.Fragment>
                                                        :
                                                        " "
                                                    }
                                                </React.Fragment>
                                            }
                                            {(indexNew == this.state.temp_var_for_andor3) &&
                                                <React.Fragment>
                                                    {
                                                        this.ifFourFilter() ?
                                                            <React.Fragment>
                                                            <div className={"col-12 order--6"+(this.state.temp_var_for_link3? " reduse_margin" : "")}  rj={indexNew}>
                                                                <div className={"row m-0 "} >
                                                                    <div className="col-6 p-0_ d-flex align-items-center justify-content-start andor_icon">
                                                                        
                                                                            <p className={"mb-0 me-2 and_or_text "+ (this.state.andOrFilter3_status ? "" : "active_text")}>{this.state.temp_var_for_link3  == 1 ? "and" : "AND"}</p>

                                                                            <div className="form-check form-switch m-0">
                                                                                <input className="form-check-input switch" 
                                                                                checked={this.state.andOrFilter3_status} type="checkbox" role="switch" id="andOrFilter3_status"

                                                                                    onChange={this.andOrMethod3.bind(this, !this.state.andOrFilter3_status)} />
                                                                            </div>

                                                                            <p className={"mb-0 and_or_text "+ (this.state.andOrFilter3_status ? "active_text" : "")}>{this.state.temp_var_for_link3  == 1 ? "or" : "OR"}</p>
                                                                        
                                                                    </div>
                                                                    <div className="col-6 p-0 d-flex align-items-center justify-content-end ">
                                                                        <div className="link_icon">
                                                                            { this.state.show_link3 == 1 &&
                                                                                <FontAwesomeIcon icon="fa-solid fa-link" className={"cursor_pointer"+ (this.state.temp_var_for_link3? " active_radius_class" : "")} onClick={()=>this.removeLinking(3)} />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </React.Fragment>
                                                        :
                                                        " "
                                                    }
                                                </React.Fragment>
                                            }
                                            </React.Fragment>
                                          ))
                                        }
                                        
                                    </div>

                                    {/* Phase  */}

                                    {/* Members - Members Found */}

                                    {/* { this.state.doctorPosition.length } */}
                                    <div className="row m-0 mt-4_">
                                        {(this.state.doctorStatusId || this.state.studentStatusId) ?
                                            <div className="col-lg-12 col-md-12 col-12 p-lg-4 p-md-4 p-2 cards_parent">

                                                <div className="row m-0">
                                                    
                                                        <div className="col-lg-3 col-md-5 col-12 p-0 d-flex align-items-center">
                                                            <OverlayTrigger overlay={(props) => (<Tooltip {...props}> Bookmark (save search filters) </Tooltip>)} placement="top" >
                                                                <button className={"filter_buttons d-flex align-items-center  justify-content-center " + (this.state.bookmarkType === "B" ? "active_filter_buttons" : "")}
                                                                    onClick={this.BookmarkHandler.bind(this, "B")}  >B</button>
                                                            
                                                            </OverlayTrigger>
                                                            {(this.state.advanceFilter) &&
                                                                <OverlayTrigger overlay={(props) => (<Tooltip {...props}> Reset (to the default configuration of and/or operators) </Tooltip>)} placement="top" >
                                                                    <button className={"ms-2 filter_buttons d-flex align-items-center  justify-content-center " + (this.state.bookmarkType === "R" ? "active_filter_buttons" : "")}
                                                                    onClick={this.BookmarkHandler.bind(this, "R")}  >R</button>
                                                            
                                                                </OverlayTrigger>
                                                            }
                                                            <OverlayTrigger overlay={(props) => (<Tooltip {...props}> Delete (all search filters) </Tooltip>)} placement="top" >
                                                                <button className={"ms-2 filter_buttons d-flex align-items-center  justify-content-center " + (this.state.bookmarkType === "D" ? "active_filter_buttons" : "")}
                                                                    onClick={this.BookmarkHandler.bind(this, "D")}  >D</button>
                                                            </OverlayTrigger>
                                                        </div>
                                                        
                                                    
                                                    <div className="col-lg-9 col-md-7 col-12 p-0 mt-lg-0 mt-md-2 mt-2">

                                                        <div className="row m-0 mt-lg-0 mt-md-0 mt-3">
                                                            <div className="col-12 p-0">
                                                                <p className="members_found_text mb-0">Members selected:</p>
                                                            </div>
                                                        </div>

                                                        <div className="row m-0 mt-lg-2 mt-md-3 mt-3">
                                                            <div className="col-6 p-0">
                                                                <div className="mb-0 total_found_text text-end mt-4">
                                                                    <sup className="super_text">
                                                                        { 
                                                                            (this.state.isLoadingCount === true ? 
                                                                            
                                                                                <Spinner animation="border" variant="dark" />
                                                                            
                                                                                :
                                                                                <React.Fragment>
                                                                                    {siteUsers && siteUsers.total_siteusers}  
                                                                                </React.Fragment>
                                                                            ) 
                                                                        }
                                                                        
                                                                    </sup> 
                                                                    <span className="devider">/</span> <sub className="sub_text">{siteUsers && siteUsers.all_siteusers}</sub></div>
                                                                <p className="mb-0 text-end total_mbr_info"> total members</p>
                                                            </div>
                                                            <div className="col-6 p-0 d-flex align-items-center justify-content-end">
                                                                { siteUsers && siteUsers.total_siteusers > 0 &&
                                                                <button onClick={this.activeMsgArea.bind(this)} className="p-2 wrt_msg_btn">Write your message</button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            
                                            <div className="col-lg-12 col-md-12 col-12 p-lg-4 p-md-4 p-2 cards_parent">

                                                <div className="row m-0">
                                                    
                                                        <div className="col-lg-3 col-md-5 col-12 p-0 d-flex align-items-center">
                                                            <OverlayTrigger overlay={(props) => (<Tooltip {...props}> Bookmark (save search filters) </Tooltip>)} placement="top" >
                                                                <button className={"filter_buttons d-flex align-items-center  justify-content-center " + (this.state.bookmarkType === "B" ? "active_filter_buttons" : "")}
                                                                    onClick={this.BookmarkHandler.bind(this, "B")} >B</button>
                                                            </OverlayTrigger>
                                                            {(this.state.advanceFilter) &&
                                                                <OverlayTrigger overlay={(props) => (<Tooltip {...props}> Reset (to the default configuration of and/or operators) </Tooltip>)} placement="top" >
                                                                        <button className={"ms-2 filter_buttons d-flex align-items-center justify-content-center " + (this.state.bookmarkType === "R" ? "active_filter_buttons" : "")}
                                                                            onClick={this.BookmarkHandler.bind(this, "R")} >R</button>
                                                                </OverlayTrigger>
                                                            }
                                                            <OverlayTrigger overlay={(props) => (<Tooltip {...props}> Delete (all search filters) </Tooltip>)} placement="top" >
                                                                <button className={"ms-2 filter_buttons d-flex align-items-center justify-content-center " + (this.state.bookmarkType === "D" ? "active_filter_buttons" : "")}
                                                                    onClick={this.BookmarkHandler.bind(this, "D")} >D</button>
                                                            </OverlayTrigger>
                                                        </div>
                                                        
                                                    <div className="col-lg-9 col-md-7 col-12 p-0 mt-lg-0 mt-md-2 mt-4 d-flex align-items-center justify-content-center">

                                                        <img className="mx-4 warining_img" src={ErrorWarning} alt="" />

                                                        <p className="mb-0 warning_text">No member matching your selection.</p>
                                                    </div>

                                                </div>

                                            </div>
                                            
                                        }
                                    </div>

                                    

                                    {/* Message Box  */}
                                    {
                                        this.state.activeMsgArea &&
                                        <div>
                                            <React.Fragment>
                                            {siteUsers && siteUsers.total_siteusers > 0 &&
                                                <textarea className="form-control text_area mt-4" id="exampleFormControlTextarea1" placeholder="Type your message..." rows="10"></textarea>
                                            }
                                            {/* Message Box  */}

                                            {
                                                siteUsers && siteUsers.total_siteusers > 100 &&
                                                <React.Fragment>
                                                    {/* Budget Section  */}
                                                    <div className="row m-0 mt-4">
                                                        <div className="col-lg-12 col-md-12 col-12 p-lg-0 p-md-0 p-0">

                                                            <p className="mb-0 me-2 adv_filter_text">Adjust you reach to fit your budget: </p>

                                                            <div className="row m-0 mt-2">
                                                                <div className="col-6 p-0 d-flex">
                                                                    <button className="py-2 px-3 me-lg-4 me-2 free_amount_btn active_free_amount_btn"
                                                                        onClick={this.setFreeMemberCount.bind(this)}>Free</button>

                                                                    <div className="input-group paid_amount_box">
                                                                        <span className="input-group-text" id="basic-addon1">&#163;</span>
                                                                        <input type="number" className="form-control ammount_box" placeholder="Amount" 
                                                                            value={this.state.msg_price} 
                                                                            onChange={this.updatepriceMethod.bind(this)}
                                                                            aria-label="Username" aria-describedby="basic-addon1" />
                                                                    </div>

                                                                </div>

                                                                <div className="col-6 p-0 d-flex align-items-center justify-content-end">
                                                                    <button className="py-2 px-3 free_amount_btn active_free_amount_btn"
                                                                        onClick={this.selectAllMember.bind(this)}
                                                                    >All matches</button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    {/* Budget Section  */}

                                                    {/* Rang for Budget  */}

                                                    <div className="row m-0 mt-5">
                                                        <div className="col-lg-12 col-md-12 col-12 p-lg-0 p-md-0 p-0 rang_wpapper">

                                                            <div className="wrapper hide d-none">
                                                                <div className="price__wrapper">
                                                                    <span className="price-from">0</span>
                                                                    <span className="price-to">{siteUsers && siteUsers.total_siteusers}</span>
                                                                </div>
                                                            </div>

                                                            
                                                            <div className="cost_slider">
                                                                <SliderInput 
                                                                    onChange={this.memberCountMethod.bind(this)}
                                                                    onMouseUp={this.memberCountMethodCall.bind(this)}
                                                                    onTouchEnd={this.memberCountMethodCall.bind(this)}
                                                                    min={0} max={siteUsers && siteUsers.total_siteusers} step={1} value={this.state.member_count}
                                                                    
                                                                    className="ewclass"
                                                                    orientation="horizontal"
                                                                >   
                                                                    <SliderTrack>
                                                                        <SliderRange value={siteUsers && siteUsers.total_siteusers} >
                                                                            <span>{siteUsers && siteUsers.total_siteusers}</span>
                                                                        </SliderRange>
                                                                        
                                                                        {/*<OverlayTrigger defaultShow={true} overlay={(props) => (<Tooltip {...props}> {this.state.member_count} </Tooltip>)} placement="top" >*/}
                                                                            <SliderHandle>
                                                                                <span className="">{ this.state.member_count == 0  ? "" :(this.state.member_count == siteUsers.total_siteusers ? "" : this.state.member_count)}</span>
                                                                            </SliderHandle>
                                                                        {/*</OverlayTrigger>*/}
                                                                        {this.state.inputMarkers.map((value) => (
                                                                          <SliderMarker value={value} key={value}>
                                                                            <span className={value == 0? "firstMarker" : "" + value == siteUsers.total_siteusers ? "lastMarker" : ""}>
                                                                              {value == 0? value : ""} {value == siteUsers.total_siteusers ? value : ""}
                                                                            </span>
                                                                          </SliderMarker>
                                                                        ))}
                                                                    </SliderTrack>
                                                                </SliderInput>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            }
                                                <div className="row m-0 mt-4">
                                                    <div className="col-lg-8 col-md-8 col-12 p-lg-0 p-md-0 p-0 px-lg-5 d-flex align-items-center justify-content-center">
                                                        {siteUsers.total_siteusers <= 100 ?
                                                            <p className="mb-0 text-center delivery_msg">Your message will be sent to the <b>{this.state.member_count_final}</b> members matching your criteria.</p>
                                                        :
                                                            <p className="mb-0 text-center delivery_msg">Your message will be sent to <b>{this.state.member_count_final}</b> members randomly chosen among those matching your criteria.</p>
                                                        }
                                                    </div>

                                                    <div className="col-lg-4 col-md-4 col-12 p-0 mt-lg-0 mt-md-0 mt-4 d-flex align-items-center justify-content-lg-end justify-content-md-end justify-content-center">
                                                        <button className="send_button">
                                                            <p className="mb-0 send_text text-center">Send</p>
                                                            <p className="mb-0 send_amount text-center">({this.state.msg_price < 1 ? "free" : "£ "+ this.state.msg_price})</p>
                                                        </button>
                                                    </div>
                                                </div>
                                            
                                            </React.Fragment>
                                        </div>
                                    }
                                    {/* Rang for Budget  */}

                                    {/* Progress Bar Verient One  */}

                                    {
                                        this.state.activeMsgArea === false &&
                                        <React.Fragment>
                                        {
                                            this.state.advanceFilter && (this.state.location.length > 0 || this.state.specialities.length > 0|| this.state.diseases.length > 0 || this.state.intervention.length > 0 || (this.state.docChecked && this.state.stuChecked)) &&
                                            <div className="row m-0 mt-4">
                                                <div className="col-lg-12 col-md-12 col-12 p-lg-4 p-md-4 p-2 cards_parent">
                                                    {siteUsers &&
                                                        <React.Fragment>
                                                            { this.state.docChecked && this.state.stuChecked ? (
                                                                <div className={"row m-0 " + (siteUsers && siteUsers.doctors_count > 0 ? "" : " section_opacity")} >
                                                                    <div className="col-12 p-0 progress_bar">

                                                                        <div className="row m-0">
                                                                            <div className="col-12 p-0">
                                                                                <p className="mb-0 progress_heading">SENIORITY</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row m-0 mt-2">
                                                                            <div className="col-lg-6 col-md-12 col-12 p-0">

                                                                                <div className="progress m-0 mt-1 p-0">
                                                                                    <div className="progress-bar m-0" role="progressbar" style={{ width: (Math.round((siteUsers.doctors_count * 100) / siteUsers.all_siteusers)) + "%" }} aria-valuenow="45" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                </div>

                                                                            </div>
                                                                            <div className="col-lg-6 col-md-12 col-12 p-0 ps-lg-4 mt-lg-0 mt-md-2 mt-2">
                                                                                <p className="mb-0 progress_found_members"><span>{siteUsers && siteUsers.doctors_count}</span> members matching this criteria</p>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            ) : ("")
                                                            }

                                                            { this.state.location.length ? (
                                                                <div className={"row m-0 mt-4" + (this.state.locationChecked ? "" : " section_opacity")} >
                                                                    <div className="col-12 p-0 progress_bar">

                                                                        <div className="row m-0">
                                                                            <div className="col-12 p-0">
                                                                                <p className="mb-0 progress_heading">LOCATION</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row m-0 mt-2">
                                                                            <div className="col-lg-6 col-md-12 col-12 p-0">

                                                                                <div className="progress m-0 mt-1 p-0">
                                                                                    <div className="progress-bar m-0" role="progressbar" style={{ width: (Math.round((siteUsers.location_count * 100) / siteUsers.all_siteusers)) + "%" }} aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                </div>

                                                                            </div>
                                                                            <div className="col-lg-6 col-md-12 col-12 p-0 ps-lg-4 mt-lg-0 mt-md-2 mt-2">
                                                                                <p className="mb-0 progress_found_members"><span>{siteUsers.location_count}</span> members matching this criteria</p>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            ) : ("")
                                                            }
                                                            { this.state.specialities.length ? (
                                                                <div className={"row m-0 mt-4" + (this.state.specialitChecked ? "" : " section_opacity")} >
                                                                    <div className="col-12 p-0 progress_bar">

                                                                        <div className="row m-0">
                                                                            <div className="col-12 p-0">
                                                                                <p className="mb-0 progress_heading">SPECIALITY</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row m-0 mt-2">
                                                                            <div className="col-lg-6 col-md-12 col-12 p-0">

                                                                                <div className="progress m-0 mt-1 p-0">
                                                                                    <div className="progress-bar m-0" role="progressbar" style={{ width: (Math.round((siteUsers.speciality_count * 100) / siteUsers.all_siteusers)) + "%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                </div>

                                                                            </div>
                                                                            <div className="col-lg-6 col-md-12 col-12 p-0 ps-lg-4 mt-lg-0 mt-md-2 mt-2">
                                                                                <p className="mb-0 progress_found_members"><span>{siteUsers.speciality_count}</span> members matching this criteria</p>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            ) : ("")
                                                            }
                                                            { this.state.diseases.length ? (
                                                                <div className={"row m-0 mt-4" + (this.state.diseaseChecked ? "" : " section_opacity")} >
                                                                    <div className="col-12 p-0 progress_bar">

                                                                        <div className="row m-0">
                                                                            <div className="col-12 p-0">
                                                                                <p className="mb-0 progress_heading">DISEASE</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row m-0 mt-2">
                                                                            <div className="col-lg-6 col-md-12 col-12 p-0">

                                                                                <div className="progress m-0 mt-1 p-0">
                                                                                    <div className="progress-bar m-0" role="progressbar" style={{ width: (Math.round((siteUsers.disease_count * 100) / siteUsers.all_siteusers)) + "%" }} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                </div>

                                                                            </div>
                                                                            <div className="col-lg-6 col-md-12 col-12 p-0 ps-lg-4 mt-lg-0 mt-md-2 mt-2">
                                                                                <p className="mb-0 progress_found_members"><span>{siteUsers && siteUsers.disease_count}</span> members matching this criteria</p>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            ) : ("")
                                                            }
                                                            { this.state.intervention.length ? (
                                                                <div className={"row m-0 mt-4" + (this.state.interventionChecked ? "" : " section_opacity")} >
                                                                    <div className="col-12 p-0 progress_bar">

                                                                        <div className="row m-0">
                                                                            <div className="col-12 p-0">
                                                                                <p className="mb-0 progress_heading">INTERVENTION</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row m-0 mt-2">
                                                                            <div className="col-lg-6 col-md-12 col-12 p-0">

                                                                                <div className="progress m-0 mt-1 p-0">
                                                                                    <div className="progress-bar m-0" role="progressbar" style={{ width: (Math.round((siteUsers.intervention_count * 100) / siteUsers.all_siteusers)) + "%" }} aria-valuenow="45" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                </div>

                                                                            </div>
                                                                            <div className="col-lg-6 col-md-12 col-12 p-0 ps-lg-4 mt-lg-0 mt-md-2 mt-2">
                                                                                <p className="mb-0 progress_found_members"><span>{siteUsers && siteUsers.intervention_count}</span> members matching this criteria</p>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            ) : ("")
                                                            }
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        </React.Fragment>
                                    }
                                    {/* Progress Bar Verient one   */}

                                </section>
                            </div>

                        </div>

                        <div className="col-lg-2 col-md-2 col-12 p-0"> </div>

                    </div>
                </section>
            </>
        );
    }
}

function mapStateToProps(state) {
    const { siteUserPosition, siteUserAcademicYears, searchResults, searchResultsAll, siteUserStatus, siteUsers } = state.rootReducer.onCall;
    return {
        siteUserPosition,
        siteUserAcademicYears,
        siteUserStatus,
        siteUsers,
        searchResults,
        searchResultsAll
    };
}

export default connect(mapStateToProps)(Index);