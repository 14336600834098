import React, { Component } from "react";
import "./App.css";
import "./assets/css/range.css";
import "./assets/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Router, Route } from "react-router-dom";
import { connect } from "react-redux";
import Oncall from "./Components/onCall/Index";
import { history } from "./helpers";
import { alertActions } from "./actions";

class App extends Component {
    constructor(props) {
        super(props);
        const { dispatch } = this.props;
        history.listen((location, action) => {
            console.log(location, action);
            dispatch(alertActions.clear());
        });
    }
    
    render() {
        //const login = localStorage.getItem("isLoggedIn");
        return (
            <div>
                <Router history={history}>
                    <div>
                        <Route exact path="/" component={Oncall} />
                    </div>
                </Router>
            </div>
        );
    }
}
export default connect()(App);