// import { authHeader } from "../helpers";
import { APIURL } from "../constants/config";

export const onCallServices = {
    getSiteUsersStatus,
    getSiteUsersPosition,
    getSiteUsersAcademicYears,
    getSiteUsers,
    
    
    getSearchAll,
    
};

function getSiteUsersStatus() {
    const requestOptions = {
        method: "GET",
        headers: {}
    };

    //return fetch(APIURL + "siteusers-status", requestOptions).then(handleResponse);
    return fetch(APIURL + "siteusers-preload", requestOptions).then(handleResponse);
}

function getSiteUsersPosition() {
    const requestOptions = {
        method: "GET",
        headers: {}
    };

    return fetch(APIURL + "siteusers-position", requestOptions).then(handleResponse);
}

function getSiteUsersAcademicYears() {
    const requestOptions = {
        method: "GET",
        headers: {}
    };

    return fetch(APIURL + "siteusers-academic-years", requestOptions).then(handleResponse);
}


function getSiteUsers(data) {
    const requestOptions = {
        method: "POST",
        // mode: "no-cors",
        headers: {  "Content-Type": "application/json" },
        body: JSON.stringify(data)
    };

    return fetch(APIURL + "siteusers", requestOptions)
        .then(handleResponse)
        .then(record => {
            return record;
        });
}



function getSearchAll(search) {
    const requestOptions = {
        method: "POST",
        // mode: "no-cors",
        headers: {  "Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(APIURL + "siteusers-search", requestOptions)
        .then(handleResponse)
        .then(record => {
            return record;

        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        //const data = text;
        if (!response.ok) {
            if (response.status === 400) {
                // auto logout if 401 response returned from api
                // logout();
                // location.reload(true);
                const error = (data && data.data.errors) || response.statusText;
                return Promise.reject(error);
            }

            // const error = (data && data.message) || response.statusText;
            // return Promise.reject(error);
        }

        return data;
    });
}